import { Modal, Card,  Typography } from "@mui/material";
import ValueInput from "./ValueInput";
import { useState } from "react";
import SelectInput from "./SelectInput";
import CustomButton from "./CutomButton";

const parkTypes = [
  "Garden",
  "Bhavan_Rachana",
  "Health",
  "Education",
  "Social_Development",
  "Road",
];

const AddParkModel = ({ isLoading, onAdd, isOpen, onClose }) => {
  const [parkData, setParkData] = useState({
    parkName: "",
    parkType: parkTypes[0],
  });

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          flexDirection: "row",
          minHeight: "500px",
          maxHeight: "600px",
          maxWidth: "700px",
          minWidth: "500px",
          background: "#FFF",
          borderRadius: "10px",
          padding: "20px 30px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
          border: "none",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            width: "100%",
            textAlign: "center",
            marginBottom: "40px",
            marginTop: "10px",
          }}
          style={{
            textShadow: "0px 1px 1px rgba(0,0,0,0.24)",
            color: "#262626",
            fontWeight: "bold",
          }}
          fontSize={"25px"}
        >
          Add Facility
        </Typography>

        <ValueInput
          label={"Enter Facility Name"}
          value={parkData.parkName}
          onValueChange={(dt) => {
            setParkData((p) => {
              return {
                ...p,
                parkName: dt,
              };
            });
          }}
          required={true}
          type={"text"}
        />
        <SelectInput
          label={"Choose Department"}
          value={parkData.parkType}
          options={parkTypes}
          onValueChange={(dt) => {
            setParkData((p) => {
              return {
                ...p,
                parkType: dt,
              };
            });
          }}
          required={true}
        />
        <CustomButton
          isLoading={isLoading}
          label={"Add Facility"}
          onClick={() => {
            onAdd(parkData)
          }}
        />
      </Card>
    </Modal>
  );
};

export default AddParkModel;
