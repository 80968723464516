import Graph from "../../../utils/graph"


const averageTimeFinder = (
    analytics,
    isPark,
    isSpan,
    isBar,
    allOptions,
    sParks,
    allSpans
) => {

    const options = ["Childrens"]

    if (!isPark && !isSpan) {


        if (isBar) {
            return sParks.map((park, pIndex) => {
                return {
                    label: `${park} Average Time spent`,
                    data: allSpans.map((span) => {
                        let timespent = analytics[park][span.year][span.part]["Total Time Spent"]
                        let childs = Object.values(analytics[park][span.year][span.part]).reduce((acc, cur) => { return acc + Number(cur) }, 0)
                        return (Math.floor((timespent / childs) * 100) / 100)
                    }),
                    borderColor: Graph.Colors[pIndex],
                    backgroundColor: Graph.Colors[pIndex],
                    tension: 0.2,
                    pointBorderWidth: 7,
                    hoverBorderWidth: 10,
                    stack: `${park}`
                }
            })
        }

        return sParks.map((park, pIndex) => {
            return {
                label: `${park} Average Time Spent`,
                data: allSpans.map((span) => {
                    let timespent = analytics[park][span.year][span.part]["Total Time Spent"]
                    let childs = options.reduce((acc, cur) => {
                        return acc + analytics[park][span.year][span.part][cur]
                    }, 0)
                    return (Math.floor((timespent / childs) * 100) / 100)
                }),
                borderColor: Graph.Colors[pIndex],
                backgroundColor: Graph.Colors[pIndex],
                tension: 0.2,
                pointBorderWidth: 7,
                hoverBorderWidth: 10,
            }
        })
    } else if (isPark && !isSpan) {

        if (isBar) {
            return [{
                label: `Average Time spent`,
                data: allSpans.map((span) => {
                    let childs = sParks.reduce((acc, cur) => {
                        return acc + Object.values(analytics[cur][span.year][span.part]).reduce((acc, cur) => { return acc + Number(cur) }, 0)
                    }, 0)
                    let timespent = sParks.reduce((acc, cur) => {
                        return acc + analytics[cur][span.year][span.part]["Total Time Spent"]
                    }, 0)
                    return (Math.floor((timespent / childs) * 100) / 100)
                }),
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                tension: 0.2,
                pointBorderWidth: 7,
                hoverBorderWidth: 10,
                stack: "All Parks"
            }]
        }

        return [
            {
                label: "Average Time spent",
                data: allSpans.map((span) => {
                    let childs = sParks.reduce((acc, cur) => {
                        return acc + options.reduce((occ, ocur) => {
                            return occ + analytics[cur][span.year][span.part][ocur]
                        }, 0)
                    }, 0)

                    let timespent = sParks.reduce((acc, cur) => {
                        return acc + analytics[cur][span.year][span.part]["Total Time Spent"]
                    }, 0)
                    return (Math.floor((timespent / childs) * 100) / 100)
                }),
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                tension: 0.2,
                pointBorderWidth: 7,
                hoverBorderWidth: 10,
            }
        ]
    } else if (!isPark && isSpan) {


        if (isBar) {
            return [{
                label: `Average Time spent`,
                data: sParks.map((park) => {
                    let childs = allSpans.reduce((acc, cur) => {
                        return acc + Object.values(analytics[park][cur.year][cur.part]).reduce((acc, cur) => { return acc + Number(cur) }, 0)
                    }, 0)
                    let timespent = allSpans.reduce((acc, cur) => {
                        return acc + analytics[park][cur.year][cur.part]["Total Time Spent"]
                    }, 0)
                    return (Math.floor((timespent / childs) * 100) / 100)
                }),
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                tension: 0.2,
                pointBorderWidth: 7,
                hoverBorderWidth: 10,
                stack: `All Dates`
            }]
        }

        return [{
            label: "Average Time spent",
            data: sParks.map((park) => {
                let childs = allSpans.reduce((acc, cur) => {
                    return acc + options.reduce((occ, ocur) => {
                        return occ + analytics[park][cur.year][cur.part][ocur]
                    }, 0)
                }, 0)

                let timespent = allSpans.reduce((acc, cur) => {
                    return acc + analytics[park][cur.year][cur.part]["Total Time Spent"]
                }, 0)

                return (Math.floor((timespent / childs) * 100) / 100)
            }),
            borderColor: Graph.Colors[0],
            backgroundColor: Graph.Colors[0],
            tension: 0.2,
            pointBorderWidth: 7,
            hoverBorderWidth: 10,
        }]
    } else if (isPark && isSpan) {

        if (isBar) {

            return [{
                label: `Average Time spent`,
                data: [
                    (
                        () => {
                            let childs = allSpans.reduce((acc, cur) => {
                                return acc + sParks.reduce((pcc, pcur) => {
                                    return pcc + Object.values(analytics[pcur][cur.year][cur.part]).reduce((acc, cur) => {
                                        return acc + Number(cur)
                                    }, 0)
                                }, 0)
                            }, 0)

                            let timespent = allSpans.reduce((acc, cur) => {
                                return acc + sParks.reduce((pcc, pcur) => {
                                    return pcc + analytics[pcur][cur.year][cur.part]["Total Time Spent"]
                                }, 0)
                            }, 0)
                            return (Math.floor((timespent / childs) * 100) / 100)
                        }
                    )()
                ],
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                tension: 0.2,
                pointBorderWidth: 7,
                hoverBorderWidth: 10,
                stack: "All Dates and Parks"
            }]

        }


        return [
            {
                label: "Average Time spent",
                data: [
                    (
                        () => {

                            let childs = allSpans.reduce((acc, cur) => {
                                return acc + sParks.reduce((pcc, pcur) => {
                                    return pcc + options.reduce((occ, ocur) => {
                                        return occ + analytics[pcur][cur.year][cur.part][ocur]
                                    }, 0)
                                }, 0)
                            }, 0)

                            let timespent = allSpans.reduce((acc, cur) => {
                                return acc + sParks.reduce((pcc, pcur) => {
                                    return pcc + analytics[pcur][cur.year][cur.part]["Total Time Spent"]
                                }, 0)
                            }, 0)
                            return (Math.floor((timespent / childs) * 100) / 100)
                        }
                    )()
                ],
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                tension: 0.2,
                pointBorderWidth: 7,
                hoverBorderWidth: 10,
            }
        ]

    }

}

export default averageTimeFinder