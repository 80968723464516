import {
  MenuItem,
  FormControl,
  Checkbox,
  Button,
  Typography,
  Grow,
  Paper,
  MenuList,
  Popper,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

const GraphSelector = ({
  allOptions,
  selectedOptions,
  onChange,
  width,
  isSingle,
  label
}) => {
  const buttonRef = useRef(undefined);
  const popperRef = useRef(undefined);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <FormControl>
      <Button
        ref={buttonRef}
        sx={{
          width: width,
          border: `1px solid #262626`,
          outline: isVisible ? `1px solid #262626` : "1px solid transparent",
          fontSize: "16px",
          height: "55px",
        }}
        onClick={() => {
          setIsVisible((p) => !p);
        }}
      >
        <Typography
          noWrap
          sx={{
            padding: "9px 12px",
            textOverflow: "ellipsis",
            textTransform: "none",
            wordBreak: "break-all",
            maxLines: 1,
            color: "#262626",
          }}
          variant="h6"
          fontSize={"15px"}
          color="primary.main"
        >
          {Boolean(label) ? label : selectedOptions.join(", ")}
        </Typography>
      </Button>

      <Popper
        anchorEl={buttonRef.current}
        role={undefined}
        placement="bottom"
        open={isVisible && Boolean(buttonRef.current)}
        disablePortal={true}
        transition
        ref={popperRef}
        sx={{
          // maxWidth: "600px",
          margin: "10px",
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "center top" }}>
            <Paper
              elevation={10}
              sx={{
                marginTop: "3px",
                maxHeight: "450px",
                overflow: "scroll",
              }}
            >
              <MenuList>
                {!isSingle && (
                  <MenuItem
                    onClick={() => {
                      if (selectedOptions.length === allOptions.length) {
                        onChange([]);
                      } else {
                        onChange([...allOptions]);
                      }
                    }}
                    key="All"
                  >
                    <Checkbox
                      checked={selectedOptions.length === allOptions.length}
                    />
                    <Typography variant="h6" color="primary">
                      All
                    </Typography>
                  </MenuItem>
                )}

                {[...allOptions].map((option) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        if (isSingle) {
                          onChange([option]);
                        } else {
                          if (selectedOptions.includes(option)) {
                            let sHot = [...selectedOptions];
                            sHot.splice(sHot.indexOf(option), 1);
                            onChange(sHot);
                          } else {
                            onChange([...selectedOptions, option]);
                          }
                        }
                      }}
                      key={option}
                      value={option}
                      color="primary"
                    >
                      <Checkbox
                        checked={selectedOptions.indexOf(option) > -1}
                      />
                      <Typography
      
                        sx={{
                          // textOverflow: "ellipsis",
                          maxWidth: "600px",
                          overflow:"scroll",
                          "::-webkit-scrollbar":{
                            display:"none"
                          }
                        }}
                        variant="h6"
                        color="primary"
                      >
                        {option}{" "}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </FormControl>
  );
};

export default GraphSelector;
