import { useState } from "react";
import { Modal, Card, Typography } from "@mui/material";
import ValueInput from "./ValueInput";
import CustomButton from "./CutomButton";
import ComponentSelectInput from "./ComponentSelectInput";
import Park from "./Park";


const AddProviderModel = ({ isLoading, onAdd, isOpen, onClose, parks }) => {
  const [providerData, setProviderData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    username: "",
    password: "",
    address: "",
    parkId: "",
    canEditData: false,
  });

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          flexDirection: "row",
          minHeight: "500px",
          maxHeight: "600px",
          maxWidth: "700px",
          minWidth: "500px",
          background: "#FFF",
          borderRadius: "10px",
          padding: "20px 30px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
          border: "none",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            width: "100%",
            textAlign: "center",
            marginBottom: "40px",
            marginTop: "10px",
          }}
          style={{
            textShadow: "0px 1px 1px rgba(0,0,0,0.24)",
            color: "#262626",
            fontWeight: "bold",
          }}
          fontSize={"25px"}
        >
          Add Provider
        </Typography>

        <ValueInput
          label={"Enter Provider Name"}
          value={providerData.name}
          onValueChange={(dt) => {
            setProviderData((p) => {
              return {
                ...p,
                name: dt,
              };
            });
          }}
          required={true}
          type={"text"}
        />

        <ValueInput
          label={"Enter Provider Email"}
          value={providerData.email}
          onValueChange={(dt) => {
            setProviderData((p) => {
              return {
                ...p,
                email: dt,
              };
            });
          }}
          required={true}
          type={"email"}
        />

        <ValueInput
          label={"Enter Provider Phone Number"}
          value={providerData.phoneNumber}
          onValueChange={(dt) => {
            setProviderData((p) => {
              return {
                ...p,
                phoneNumber: dt,
              };
            });
          }}
          required={true}
          type={"number"}
        />

        <ValueInput
          label={"Enter Provider Username"}
          value={providerData.username}
          onValueChange={(dt) => {
            setProviderData((p) => {
              return {
                ...p,
                username: dt,
              };
            });
          }}
          required={true}
          type={"text"}
        />

        <ValueInput
          label={"Enter Provider Password"}
          value={providerData.password}
          onValueChange={(dt) => {
            setProviderData((p) => {
              return {
                ...p,
                password: dt,
              };
            });
          }}
          required={true}
          type={"text"}
        />

        <ValueInput
          label={"Enter Provider Address"}
          value={providerData.address}
          onValueChange={(dt) => {
            setProviderData((p) => {
              return {
                ...p,
                address: dt,
              };
            });
          }}
          required={true}
          type={"text"}
        />

        <ComponentSelectInput
          label={"Choose Facility"}
          value={providerData.parkId}
          datas={parks}
          component={(dt) => {
            return <Park data={dt} />;
          }}
          required={true}
          onValueChange={(dt) => {
            setProviderData((p) => {
              return {
                ...p,
                parkId: dt,
              };
            });
          }}
        />

        <CustomButton
          isLoading={isLoading}
          label={"Add Provider"}
          onClick={() => {
            onAdd(providerData);
          }}
        />
      </Card>
    </Modal>
  );
};

export default AddProviderModel;
