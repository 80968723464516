import axios from "axios"

const GetAnalytics = async (route, token, data,cancelToken) => {
    try {
        const response = await axios({
            method: "POST",
            url: route,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: data,
            cancelToken:cancelToken
        })
        return response.data
    } catch (err) {
        console.log(err)
        return {}
    }
}

export default GetAnalytics