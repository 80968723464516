import ImageViewer from "./Image";
import { Stack, Typography } from "@mui/material";

const ImagesViewerBox = ({ attachements }) => {


  return (
    <>
      <Stack
        direction={"column"}
        spacing={"15px"}
        sx={{
          // maxWidth: "400px",
          margin: "10px",
          padding: "15px",
          borderRadius: "10px",
          background:"#ffffff",
          marginTop: "50px",
          boxShadow:"2px 2px 10px rgba(0,0,0,0.26)"
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "600", marginBottom: "10px" }}
        >
          Images
        </Typography>
        {Object.keys(attachements).map((pName) => {
          return attachements[pName].map((url) => {
            return <ImageViewer key={url} pName={pName} url={url} />;
          });
        })}
      </Stack>
    </>
  );
};

export default ImagesViewerBox;
