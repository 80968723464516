import { CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import { DeleteForever } from "@mui/icons-material";

const Park = ({ data, isDeleting, onDelete }) => {
  return (
    <Stack
      sx={{
        borderRadius: "5px",
        border: `1px solid #262626`,
        margin: "10px 0px",
        padding: "7px 10px",
        transition: "border 1s",
        ":hover": {
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
        },
        width: "min-content",
        cursor: "pointer",
      }}
      direction="row"
      spacing={2}
    >
      <Stack direction="column" spacing={0} sx={{ minWidth: "200px" }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: "15px",
            textOverflow: "ellipsis",
            color: "#262626",
          }}
        >
          {data.parkName}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontSize: "11px",
            color: "#262626",
          }}
        >
          #{data._id}
        </Typography>
      </Stack>

      <Stack sx={{ width: "80px" }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: "13px",
            color: "#262626",
            fontWeight: "bold",
          }}
        >
          {data.parkType}
        </Typography>
      </Stack>

      <Stack>
        <IconButton
          onClick={() => {
            onDelete();
          }}
        >
          {Boolean(isDeleting) ? (
            <CircularProgress color={"secondary"} />
          ) : (
            <DeleteForever />
          )}
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default Park;
