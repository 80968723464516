import { useContext } from "react";
import { ManagementContext } from "../../store/management";
import CustomSnackBar from "../common/CustomSnackBar";
import { Stack } from "@mui/material";
import ListWrapper from "./components/ListWrapper";
import Park from "./components/Park";
import Provider from "./components/Provider";
import Department from "./components/Department";
import AddParkModel from "./components/AddParkModel";
import AddProviderModel from "./components/AddProviderModel";
import AddDepartmentAdminModel from "./components/AddDepartmentAdminModel";

const ManagementScreen = () => {
  const managementContext = useContext(ManagementContext);

  return (
    <>
      <CustomSnackBar
        isOpen={managementContext.isError}
        message={managementContext.errorMessage}
        onClose={() => {
          managementContext.onClearError();
        }}
      />

      <Stack direction={"row"} sx={{ marginTop: "-50px" }}>
        <ListWrapper
          data={managementContext.parks}
          isLoading={
            managementContext.isParksLoading || !managementContext.isParksLoaded
          }
          title={"Facilities"}
          onToggleAdder={() => {
            managementContext.onToggleParkAdder();
          }}
          rComponent={(da, key) => {
            return (
              <Park
                key={key}
                data={da}
                isDeleting={da._id === managementContext.deletingId}
                onDelete={() => {
                  managementContext.onDelete(da._id);
                }}
              />
            );
          }}
          width={39}
        />

        <ListWrapper
          data={managementContext.providers}
          isLoading={
            managementContext.isProvidersLoading ||
            !managementContext.isProvidersLoaded
          }
          title={"Providers"}
          onToggleAdder={() => {
            managementContext.onToggleProviderAdder();
          }}
          rComponent={(da, key) => {
            return (
              <Provider
                data={da}
                key={key}
                isDeleting={da._id === managementContext.deletingId}
                onDelete={() => {
                  managementContext.onDelete(da._id);
                }}
              />
            );
          }}
          width={68}
        />
      </Stack>

      <Stack direction={"row"} sx={{ marginTop: "-40px" }}>
        <ListWrapper
          data={managementContext.departmentAdmins}
          isLoading={
            managementContext.isDepartmentAdminsLoading ||
            !managementContext.isDepartmentAdminsLoaded
          }
          title={"Department Admins"}
          onToggleAdder={() => {
            managementContext.onToggleDepartmentAdminAdder();
          }}
          rComponent={(da, key) => {
            return (
              <Department
                key={key}
                data={da}
                isDeleting={da._id === managementContext.deletingId}
                onDelete={() => {
                  managementContext.onDelete(da._id);
                }}
              />
            );
          }}
          width={32}
        />
      </Stack>

      {managementContext.isParkAdderOpen && (
        <AddParkModel
          isOpen={managementContext.isParkAdderOpen}
          onClose={() => {
            managementContext.onToggleParkAdder();
          }}
          onAdd={(dt) => {
            managementContext.onAddPark(dt);
          }}
          isLoading={managementContext.isParkAdding}
        />
      )}
      {managementContext.isProviderAdderOpen && (
        <AddProviderModel
          isOpen={managementContext.isProviderAdderOpen}
          onClose={() => {
            managementContext.onToggleProviderAdder();
          }}
          onAdd={(dt) => {
            managementContext.onAddProvider(dt);
          }}
          isLoading={managementContext.isProviderAdding}
          parks={managementContext.parks}
        />
      )}
      {managementContext.isDepartmentAdminAdderOpen && (
        <AddDepartmentAdminModel
          isOpen={managementContext.isDepartmentAdminAdderOpen}
          onClose={() => {
            managementContext.onToggleDepartmentAdminAdder();
          }}
          onAdd={(dt) => {
            managementContext.onAddDepartmentAdmin(dt);
          }}
          isLoading={managementContext.isDepartmentAdminAdding}
        />
      )}
    </>
  );
};

export default ManagementScreen;
