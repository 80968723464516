

const attachementFinder = (data,selectedParks) => {

    const attachments = {};

    for (const parkName in data) {
        if (data.hasOwnProperty(parkName)) {
            attachments[parkName] = [];
            const parkData = data[parkName];
            for (const year in parkData) {
                if (parkData.hasOwnProperty(year)) {
                    const quarters = parkData[year];
                    for (const quarter in quarters) {
                        if (quarters.hasOwnProperty(quarter)) {
                            const details = quarters[quarter];
                            attachments[parkName] = attachments[parkName].concat(details.attachement || []);
                        }
                    }
                }
            }
        }
    }
    return attachments
}

export default attachementFinder