
export const DOMAIN_NAME = "https://expense.oxyhotels.com"
export const IsDepartAdminDashboard = false

const Constants = {

    authenticationRoute: `${DOMAIN_NAME}/admin/commonAuth`,

    visitorAnalyticsRoute: `${DOMAIN_NAME}/admin/getVisitorAnalytics`,

    budgetAnalyticsRoute: `${DOMAIN_NAME}/admin/getBudgetAnalytics`,

    pollutionAnalyticsRoute: `${DOMAIN_NAME}/admin/getPollution`,

    safetyAnalyticsRoute: `${DOMAIN_NAME}/admin/getSafetyAnalytics`,

    transportAnalyticsRoute: `${DOMAIN_NAME}/admin/getTransportAnalytics`,

    equipmentAnalyticsRoute: `${DOMAIN_NAME}/admin/getEquipmentAnalytics`,

    guideLines1AnalyticsRoute: `${DOMAIN_NAME}/admin/getGuideLines1Analytics`,
    guideLines2AnalyticsRoute: `${DOMAIN_NAME}/admin/getGuideLines2Analytics`,
    guideLines3AnalyticsRoute: `${DOMAIN_NAME}/admin/getGuideLines3Analytics`,

    pmcCapacityBuildingRoute: `${DOMAIN_NAME}/admin/getCapacityBuildingAnalytics/pmc`,
    serviceCapacityBuildingRoute: `${DOMAIN_NAME}/admin/getCapacityBuildingAnalytics/service`,
    caregiversCapacityBuildingRoute: `${DOMAIN_NAME}/admin/getCapacityBuildingAnalytics/caregivers`,


    serviceProviderFeedbackAnalyticRoute: `${DOMAIN_NAME}/admin/getServiceProviderFeedback`,


    getParksRoute: `${DOMAIN_NAME}/admin/getAllParks`,
    getProvidersRoute: `${DOMAIN_NAME}/admin/providers`,
    getDepartmentAdminsRoute: `${DOMAIN_NAME}/admin/getAllAdmins`,

    addParkRoute: `${DOMAIN_NAME}/admin/createPark`,
    addProviderRoute: `${DOMAIN_NAME}/admin/createProvider`,
    addDepartmentAdminRoute: `${DOMAIN_NAME}/admin/createAdmin`,

    deleteRoute: `${DOMAIN_NAME}/admin/delete`,

    visitorAnalyticsOptions: [
        "Childrens",
        "Fathers",
        "Mothers",
        "Grandparents",
        "Others"
    ],

    visitorTimeAnalyticsOptions: ["Total Time Spent"],

    safetyAnalyticsOptions: ["Unsafe", "Need Improvement", "Excellent", "Good"],

    transportAnalyticsOptions: ["Walking", "Vehicle"],

    equipmentAnalyticsOptions: ["Equipments", "Functional Equipments", "Amount", "Required"],

    pmcCapacityBuildingOptions: ["Workshops", "Participants"],
    serviceCapacityBuildingOptions: ["Workshops", "Participants"],
    caregiversCapacityBuildingOptions: ["Workshops", "Participants"],


    serviceProviderFeedbackAnalyticOptions: ["Not Satisfactory", "Excellent", "Good"],


    budgetAnalyticsOptions: ["Allocated", "Utilized", "Required"],


    pollutionAnalyticsOptions: ["Air", "Noise"],


    guideLines1AnalyticsOptions: ["Policies"],
    guideLines2AnalyticsOptions: ["Policies"],
    guideLines3AnalyticsOptions: ["Policies"],


}



export default Constants