import { useContext } from "react";
import { AnalyticsContext } from "../../store/analytics";
import Loading from "../common/Loading";
import Constants from "../../constant/Constant";
import NormalGraph from "../graph/NormalGraph";

const GuideLines2Screen = () => {
  const analyticsContext = useContext(AnalyticsContext);

  if (
    analyticsContext.isGuideLines2AnalyticsLoading ||
    !analyticsContext.isGuideLines2AnalyticsLoaded
  ) {
    return (
      <>
        <Loading />
      </>
    );
  }


  return (
    <div style={{  }}>
      <NormalGraph
        analytics={analyticsContext.guideLines2Analytics}
        isBar={analyticsContext.isBar}
        isPark={analyticsContext.isPark}
        isSpan={analyticsContext.isSpan}
        isPercentage={analyticsContext.isPercentage}
        onToggleBar={() => {
          analyticsContext.onToggleBar();
        }}
        onTogglePark={() => {
          analyticsContext.onTogglePark();
        }}
        onToggleSpan={() => {
          analyticsContext.onToggleSpan();
        }}
        onTogglePercentage={() => {
          analyticsContext.onTogglePercentage()
        }}
        title={"Feature And Component Analytics"}
        allOptions={Constants.guideLines2AnalyticsOptions}
        isStacked={analyticsContext.isStacked}
        onToggleStack={() => {
          analyticsContext.onToggleStack();
        }}
        extras={["name","incName","incorporationDate","suggestion"]}
      />
    </div>
  );
};

export default GuideLines2Screen
