import { useContext } from "react";
import { AnalyticsContext } from "../../store/analytics";
import Loading from "../common/Loading";
import Constants from "../../constant/Constant";
import NormalGraph from "../graph/NormalGraph";

const ServiceProviderFeedbackScreen = (
    
) => {

    const analyticsContext = useContext(AnalyticsContext);

    if (
      analyticsContext.isServiceProviderFeedbackAnalyticsLoading ||
      !analyticsContext.isServiceProviderFeedbackAnalyticsLoaded
    ) {
      return (
        <>
          <Loading />
        </>
      );
    }
  
    return (
      <div style={{  }}>
        <NormalGraph
          analytics={analyticsContext.serviceProviderFeedbackAnalytics}
          isBar={analyticsContext.isBar}
          isPark={analyticsContext.isPark}
          isSpan={analyticsContext.isSpan}
          isPercentage={analyticsContext.isPercentage}
          onToggleBar={() => {
            analyticsContext.onToggleBar();
          }}
          onTogglePark={() => {
            analyticsContext.onTogglePark();
          }}
          onToggleSpan={() => {
            analyticsContext.onToggleSpan();
          }}
          onTogglePercentage={() => {
            analyticsContext.onTogglePercentage()
          }}
          title={"Service Provider Feedback Analytics"}
          allOptions={Constants.serviceProviderFeedbackAnalyticOptions}
          isStacked={analyticsContext.isStacked}
          onToggleStack={() => {
            analyticsContext.onToggleStack();
          }}
        />
      </div>
    );


}

export default ServiceProviderFeedbackScreen