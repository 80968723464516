import { Button, InputLabel, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import LoginFormCss from "./LoginForm.module.css";
import { AuthContext } from "../../store/auth";
import { IsDepartAdminDashboard } from "../../constant/Constant";
import PMCTheme from "../../theme/theme";

const LoginForm = () => {
  const authContext = useContext(AuthContext);
  const [username, setUSername] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = () => {
    authContext.authenticate(username, password);
  };

  return (
    <div className={LoginFormCss.LoginFormParentContainer}>
      <Typography
        variant="h1"
        sx={{
          width: "100%",
          textAlign: "center",
          marginBottom: "40px",
          marginTop: "10px",
        }}
        style={{
          textShadow: "0px 1px 1px rgba(0,0,0,0.24)",
        }}
      >
        Welcome to {IsDepartAdminDashboard ? "Department Admin" : "Admin"}
      </Typography>

      <InputLabel
        variant="standard"
        // required
        sx={{
          width: "100%",
          margin: "8px 0px",
        }}
      >
        <Typography variant="h6" color="primary">
          Enter Username
        </Typography>
      </InputLabel>
      <TextField
        label="Username"
        variant="outlined"
        value={username}
        onChange={(e) => {
          setUSername(e.target.value);
        }}
        required
        type="text"
        margin="normal"
        maxRows={1}
        fullWidth
        sx={{
          margin: "8px 0px",
        }}
      />
      <InputLabel
        variant="standard"
        // required
        sx={{
          width: "100%",
          margin: "8px 0px",
        }}
      >
        <Typography variant="h6" color="primary">
          Enter Password
        </Typography>
      </InputLabel>
      <TextField
        label="Password"
        variant="outlined"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        required
        type="password"
        margin="normal"
        maxRows={1}
        fullWidth
        sx={{
          margin: "8px 0px",
        }}
      />
      <Button
        variant="outlined"
        size="large"
        sx={{
          borderRadius: "30px",
          padding: "10px 15px",
          margin: "20px auto",
          display: "block",
          color: PMCTheme.palette.primary.main,
          "&:hover": {
            backgroundColor: PMCTheme.palette.background.main,
            color: PMCTheme.palette.primary.main,
          },
        }}
        onClick={() => {
          onSubmit();
        }}
      >
        <Typography variant="h6">Login</Typography>
      </Button>
    </div>
  );
};

export default LoginForm;
