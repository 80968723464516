import { useContext } from "react";
import { AnalyticsContext } from "../../store/analytics";
import Loading from "../common/Loading";
import Constants from "../../constant/Constant";
import NormalGraph from "../graph/NormalGraph";
import attachementFinder from "../../utils/attachementFinder";
import ImagesViewerBox from "../common/ImagesViewerBox";

const CaregiversCapacityBuildingScreen = () => {
  const analyticsContext = useContext(AnalyticsContext);

  if (
    analyticsContext.isCaregiversCapacityBuildingAnalyticsLoading ||
    !analyticsContext.isCaregiversCapacityBuildingAnalyticsLoaded
  ) {
    return (
      <>
        <Loading />
      </>
    );
  }

 
  return (
    <div style={{}}>
      <NormalGraph
        analytics={analyticsContext.caregiversCapacityBuildingAnalytics}
        isBar={analyticsContext.isBar}
        isPark={analyticsContext.isPark}
        isSpan={analyticsContext.isSpan}
        isPercentage={analyticsContext.isPercentage}
        onToggleBar={() => {
          analyticsContext.onToggleBar();
        }}
        onTogglePark={() => {
          analyticsContext.onTogglePark();
        }}
        onToggleSpan={() => {
          analyticsContext.onToggleSpan();
        }}
        onTogglePercentage={() => {
          analyticsContext.onTogglePercentage();
        }}
        title={"Caregivers Capacity Building Analytics"}
        allOptions={Constants.caregiversCapacityBuildingOptions}
        isStacked={analyticsContext.isStacked}
        onToggleStack={() => {
          analyticsContext.onToggleStack();
        }}
        extras={["name","conductedFor","suggestion"]}
      />
      <ImagesViewerBox
        attachements={attachementFinder(analyticsContext.visitorAnalytics)}
      />
    </div>
  );
};

export default CaregiversCapacityBuildingScreen;
