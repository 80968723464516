import { Stack, Typography, Button } from "@mui/material";
import { DOMAIN_NAME } from "../../constant/Constant";

const ImageViewer = ({ pName, url }) => {
  return (
    <Stack
      direction={"row"}
      sx={{
        alignItems: "center",
        justifyContent:"space-between",
        paddingRight:"10px"
      }}
      spacing={4}
    >
      <img
        style={{ borderRadius: "7px" }}
        alt={url}
        width={"70px"}
        height={"auto"}
        src={`${DOMAIN_NAME}/documents/${url}`}
      ></img>
      <Typography variant="h6" sx={{ fontWeight: 500 }}>
        {pName}
      </Typography>
      <Button variant="contained" onClick={() => {
        window.open(`${DOMAIN_NAME}/documents/${url}`)
      }} sx={{borderRadius:"10px"}}>
        <Typography>
            View
        </Typography>
      </Button>
    </Stack>
  );
};

export default ImageViewer;
