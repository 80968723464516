import {
  FormControl,
  Button,
  Typography,
  Popper,
  Paper,
  Grow,
  Stack,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";


const GraphGroupSwitchContent = ({ content }) => {

  const buttonRef = useRef(undefined);
  const popperRef = useRef(undefined);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <FormControl
      style={{
        zIndex: "unset",
      }}
    >
      <Button
        ref={buttonRef}
        sx={{
          width: "70px",
          border: `1px solid #262626`,
          outline: isVisible ? `1px solid #262626` : "1px solid transparent",
          fontSize: "16px",
        }}
        onClick={() => {
          setIsVisible((p) => !p);
        }}
      >
        <Typography
          sx={{
            padding: "9px 12px",
            textOverflow: "ellipsis",
            textTransform: "none",
            color:"#262626"
          }}
          variant="h6"
          fontSize={"15px"}
          color="primary.main"
        >
          View
        </Typography>
      </Button>

      <Popper
        anchorEl={buttonRef.current}
        role={undefined}
        placement="bottom"
        open={isVisible && Boolean(buttonRef.current)}
        disablePortal={true}
        transition
        ref={popperRef}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "center top" }}>
            <Paper
              elevation={10}
              sx={{
                marginTop: "6px",
                maxHeight: "450px",
                overflow: "scroll",
                padding: "20px 14px",
                borderRadius: "15px",
              }}
            >
              <Stack direction={"column"} spacing={2}>
                {content}
              </Stack>
            </Paper>
          </Grow>
        )}
      </Popper>
    </FormControl>
  );
};

export default GraphGroupSwitchContent;
