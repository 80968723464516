import { createContext, useState, useCallback, useContext, useRef, useEffect } from "react"
import { AuthContext } from "./auth"
import GetAnalytics from "../utils/GetAnalytics"
import Constants from "../constant/Constant"
import axios from "axios"
import AddRequest from "../utils/AddRequest"

export const ManagementContext = createContext({

    parks: [],
    isParksLoaded: false,
    isParksLoading: false,


    providers: [],
    isProvidersLoading: false,
    isProvidersLoaded: false,

    departmentAdmins: [],
    isDepartmentAdminsLoading: false,
    isDepartmentAdminsLoaded: false,

    deletingId: undefined,


    isParkAdderOpen: false,
    isProviderAdderOpen: false,
    isDepartmentAdminAdderOpen: false,

    isParkAdding: false,
    isProviderAdding: false,
    isDepartmentAdminAdding: false,

    onAddPark: (data) => { },
    onAddProvider: (data) => { },
    onAddDepartmentAdmin: (data) => { },

    onToggleParkAdder: () => { },
    onToggleProviderAdder: () => { },
    onToggleDepartmentAdminAdder: () => { },

    isError: false,
    errorMessage: "",
    onClearError: () => { },

    onDelete: (_id) => { },

})


const ManagementContextProvider = ({ children, menuIndex }) => {

    const authContext = useContext(AuthContext)

    const [managementData, setManagementData] = useState({

        parks: [],
        isParksLoaded: false,
        isParksLoading: false,

        deletingId: undefined,

        providers: [],
        isProvidersLoading: false,
        isProvidersLoaded: false,

        departmentAdmins: [],
        isDepartmentAdminsLoading: false,
        isDepartmentAdminsLoaded: false,


        isParkAdderOpen: false,
        isProviderAdderOpen: false,
        isDepartmentAdminAdderOpen: false,

        isParkAdding: false,
        isProviderAdding: false,
        isDepartmentAdminAdding: false,

        isError: false,
        errorMessage: "",
    })


    const parkToken = useRef(null)
    const providerToken = useRef(null)
    const departmentAdminsToken = useRef(null)


    const onToggleParkAdder = useCallback(() => {
        setManagementData(m => {
            return {
                ...m,
                isParkAdderOpen: !m.isParkAdderOpen
            }
        })
    }, [])

    const onToggleProviderAdder = useCallback(() => {
        setManagementData(m => {
            return {
                ...m,
                isProviderAdderOpen: !m.isProviderAdderOpen
            }
        })
    }, [])

    const onToggleDepartmentAdminAdder = useCallback(() => {
        setManagementData(m => {
            return {
                ...m,
                isDepartmentAdminAdderOpen: !m.isDepartmentAdminAdderOpen
            }
        })
    }, [])

    const onClearError = useCallback(() => {
        setManagementData(m => {
            return {
                ...m,
                isError: false,
                errorMessage: ""
            }
        })
    }, [])


    const onLoadParks = useCallback(async () => {
        if (parkToken.current && parkToken.current.token) {
            parkToken.current.cancel()
        }
        parkToken.current = axios.CancelToken.source()
        setManagementData(m => { return { ...m, isParksLoaded: false, isParksLoading: true, parks: [] } })
        try {
            const data = await GetAnalytics(Constants.getParksRoute, authContext.authToken, {}, parkToken.current.token)
            if (!data.status || !data.data) {
                setManagementData(m => {
                    return {
                        ...m,
                        isParksLoading: false,
                        isParksLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setManagementData(m => {
                    return {
                        ...m,
                        isParksLoading: false,
                        isParksLoaded: true,
                        isError: true,
                        errorMessage: "Parks Updated Successfully.",
                        parks: data.data,
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }
    },
        [
            authContext.authToken,
        ]
    )


    const onLoadProviders = useCallback(async () => {
        if (providerToken.current && providerToken.current.token) {
            providerToken.current.cancel()
        }
        providerToken.current = axios.CancelToken.source()
        setManagementData(m => { return { ...m, isProvidersLoaded: false, isProvidersLoading: true, providers: [] } })
        try {
            const data = await GetAnalytics(Constants.getProvidersRoute, authContext.authToken, {}, providerToken.current.token)
            if (!data.status || !data.data) {
                setManagementData(m => {
                    return {
                        ...m,
                        isProvidersLoading: false,
                        isProvidersLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setManagementData(m => {
                    return {
                        ...m,
                        isProvidersLoading: false,
                        isProvidersLoaded: true,
                        isError: true,
                        errorMessage: "Providers Updated Successfully.",
                        providers: data.data,
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }
    },
        [
            authContext.authToken
        ]
    )

    const onLoadDepartmentAdmins = useCallback(async () => {
        if (departmentAdminsToken.current && departmentAdminsToken.current.token) {
            departmentAdminsToken.current.cancel()
        }
        departmentAdminsToken.current = axios.CancelToken.source()
        setManagementData(m => { return { ...m, isDepartmentAdminsLoaded: false, isDepartmentAdminsLoading: true, departmentAdmins: [] } })
        try {
            const data = await GetAnalytics(Constants.getDepartmentAdminsRoute, authContext.authToken, {}, departmentAdminsToken.current.token)
            if (!data.status || !data.data) {
                setManagementData(m => {
                    return {
                        ...m,
                        isDepartmentAdminsLoading: false,
                        isDepartmentAdminsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setManagementData(m => {
                    return {
                        ...m,
                        isDepartmentAdminsLoading: false,
                        isDepartmentAdminsLoaded: true,
                        isError: true,
                        errorMessage: "Department Admins Updated Successfully.",
                        departmentAdmins: data.data,
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }
    },
        [
            authContext.authToken
        ]
    )

    const onAddPark = useCallback(async (rData) => {
        setManagementData(p => { return { ...p, isParkAdding: true } })
        try {
            const data = await AddRequest(Constants.addParkRoute, authContext.authToken, rData)
            if (!data.status || !data.data) {
                setManagementData(m => {
                    return {
                        ...m,
                        isParkAdding: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setManagementData(m => {
                    return {
                        ...m,
                        isParkAdding: false,
                        isParkAdderOpen: false,
                        isError: true,
                        errorMessage: "Park Added Successfully.",
                        parks: [...m.parks, data.data],
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )


    const onAddProvider = useCallback(async (rData) => {
        setManagementData(p => { return { ...p, isProviderAdding: true } })
        try {
            const data = await AddRequest(Constants.addProviderRoute, authContext.authToken, rData)
            if (!data.status || !data.data) {
                setManagementData(m => {
                    return {
                        ...m,
                        isProviderAdding: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setManagementData(m => {
                    return {
                        ...m,
                        isProviderAdding: false,
                        isProviderAdderOpen: false,
                        isError: true,
                        errorMessage: "Provider Added Successfully.",
                        providers: [...m.providers, data.data],
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )

    const onAddDepartmentAdmin = useCallback(async (rData) => {
        setManagementData(p => { return { ...p, isDepartmentAdminAdding: true } })
        try {
            const data = await AddRequest(Constants.addDepartmentAdminRoute, authContext.authToken, rData)
            if (!data.status || !data.data) {
                setManagementData(m => {
                    return {
                        ...m,
                        isDepartmentAdminAdding: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setManagementData(m => {
                    return {
                        ...m,
                        isDepartmentAdminAdding: false,
                        isDepartmentAdminAdderOpen: false,
                        isError: true,
                        errorMessage: "Department Admin Added Successfully.",
                        departmentAdmins: [...m.departmentAdmins, data.data],
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )


    const onDelete = useCallback(async (_id) => {

        if (managementData.deletingId) {
            return
        }

        setManagementData(p => { return { ...p, deletingId: _id } })
        try {
            const data = await AddRequest(Constants.deleteRoute, authContext.authToken, { _id })
            if (!data.status || !data.data) {
                setManagementData(m => {
                    return {
                        ...m,
                        deletingId: undefined,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setManagementData(m => {
                    return {
                        ...m,
                        deletingId: undefined,
                        isError: true,
                        errorMessage: "Deleted Successfully.",
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken,
            managementData.deletingId
        ]
    )

    useEffect(() => {
        if ([21].includes(menuIndex)) {
            onLoadParks()
            onLoadProviders()
            onLoadDepartmentAdmins()
        }
    },

        [
            menuIndex,
            onLoadDepartmentAdmins,
            onLoadParks,
            onLoadProviders
        ]
    )

    const value = {
        ...managementData,
        onToggleDepartmentAdminAdder,
        onToggleParkAdder,
        onToggleProviderAdder,
        onClearError,
        onAddPark,
        onAddProvider,
        onAddDepartmentAdmin,
        onDelete
    }


    return (
        <ManagementContext.Provider value={value}>
            {children}
        </ManagementContext.Provider>
    )

}

export default ManagementContextProvider
