import { useState } from "react";
import { Modal, Card,  Typography } from "@mui/material";
import ValueInput from "./ValueInput";
import CustomButton from "./CutomButton";
import SelectInput from "./SelectInput";

const parkTypes = [
  "Garden",
  "Bhavan_Rachna",
  "Health",
  "Education",
  "Social_Development",
  "Road",
];

const AddDepartmentAdminModel = ({ isLoading, onAdd, isOpen, onClose }) => {
  const [adminData, setAdminData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    username: "",
    password: "",
    address: "",
    parkType: parkTypes[0],
  });

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          flexDirection: "row",
          minHeight: "500px",
          maxHeight: "600px",
          maxWidth: "700px",
          minWidth: "500px",
          background: "#FFF",
          borderRadius: "10px",
          padding: "20px 30px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
          border: "none",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            width: "100%",
            textAlign: "center",
            marginBottom: "40px",
            marginTop: "10px",
          }}
          style={{
            textShadow: "0px 1px 1px rgba(0,0,0,0.24)",
            color: "#262626",
            fontWeight: "bold",
          }}
          fontSize={"25px"}
        >
          Add Department Admin
        </Typography>

        <ValueInput
          label={"Enter Department Admin Name"}
          value={adminData.name}
          onValueChange={(dt) => {
            setAdminData((p) => {
              return {
                ...p,
                name: dt,
              };
            });
          }}
          required={true}
          type={"text"}
        />

        <ValueInput
          label={"Enter Department Admin Email"}
          value={adminData.email}
          onValueChange={(dt) => {
            setAdminData((p) => {
              return {
                ...p,
                email: dt,
              };
            });
          }}
          required={true}
          type={"email"}
        />

        <ValueInput
          label={"Enter Department Admin Phone Number"}
          value={adminData.phoneNumber}
          onValueChange={(dt) => {
            setAdminData((p) => {
              return {
                ...p,
                phoneNumber: dt,
              };
            });
          }}
          required={true}
          type={"number"}
        />

        <ValueInput
          label={"Enter Department Admin Username"}
          value={adminData.username}
          onValueChange={(dt) => {
            setAdminData((p) => {
              return {
                ...p,
                username: dt,
              };
            });
          }}
          required={true}
          type={"text"}
        />

        <ValueInput
          label={"Enter Department Admin Password"}
          value={adminData.password}
          onValueChange={(dt) => {
            setAdminData((p) => {
              return {
                ...p,
                password: dt,
              };
            });
          }}
          required={true}
          type={"text"}
        />

        <ValueInput
          label={"Enter Department Admin Address"}
          value={adminData.address}
          onValueChange={(dt) => {
            setAdminData((p) => {
              return {
                ...p,
                address: dt,
              };
            });
          }}
          required={true}
          type={"text"}
        />

        <SelectInput
          label={"Choose Department"}
          value={adminData.parkType}
          options={parkTypes}
          onValueChange={(dt) => {
            setAdminData((p) => {
              return {
                ...p,
                parkType: dt,
              };
            });
          }}
          required={true}
        />

        <CustomButton
          isLoading={isLoading}
          label={"Add Department Admin"}
          onClick={() => {
            onAdd(adminData)
          }}
        />
      </Card>
    </Modal>
  );
};

export default AddDepartmentAdminModel;
