



const allRoutes = {
    "A. Accessibility and Engagement": [
        {
            menuName: "1. Number of caregivers  accessed facilities",
            menuRouteIndex: 0,
            dataFrequency: "Monthly",
            images: true,
        },
        {
            menuName: "2. Total  time  caregivers engage in the facility",
            menuRouteIndex: 1,
            dataFrequency: "Monthly",
            images: true,
        },
        {
            menuName: "3. Average  time  caregivers engage in the facility",
            menuRouteIndex: 2,
            dataFrequency: "Monthly",
            images: true,
        },
    ],
    "B. Safety and Security of facility": [
        {
            menuName: "1. Quality of  safety within  facility",
            menuRouteIndex: 3,
            dataFrequency: "Quarterly"
        },
        {
            menuName: "2. Status of daily visitors visited by motorized and non-Motorized means",
            menuRouteIndex: 4,
            dataFrequency: "Quarterly"
        }
    ],
    "C. Status of Equipment's and Maintenance": [
        {
            menuName: "1. Available and Functional equipment’s and  Equipment Budget",
            menuRouteIndex: 5,
            dataFrequency: "Quarterly",
            images: true,
        },
        // {
        //     menuName: "2. Number of functional  Equipment's",
        //     menuRouteIndex: 6,
        //     dataFrequency: "Quarterly",
        //     images: true,
        // },
        // {
        //     menuName: "3. Cost of all equipment's  (at the time of Purchasing)",
        //     menuRouteIndex: 7,
        //     dataFrequency: "Quarterly",
        //     images: true,
        // },
        // {
        //     menuName: "2. Cost required for maintenance  of equipment's",
        //     menuRouteIndex: 8,
        //     dataFrequency: "Quarterly",
        //     images: true,
        // }
    ],
    "D. Status of Training and Behaviour changes": [
        {
            menuName: "1. Number of trainings  session Conducted for service providers",
            menuRouteIndex: 9,
            dataFrequency: "Half Yearly",
            images: true,
        },
        {
            menuName: "2. Number of trainings  session Conducted for Caregivers",
            menuRouteIndex: 10,
            dataFrequency: "Half Yearly",
            images: true,
        },
        {
            menuName: "3. Number of trainings  session Conducted for PMC Officials",
            menuRouteIndex: 11,
            dataFrequency: "Half Yearly",
            images: true,
        },
        {
            menuName: "4. % of user who provided positive feedback about  behaviour of the service provider",
            menuRouteIndex: 12,
            dataFrequency: "Half Yearly"
        }
    ],
    "E. Integration of Urban95  interventions with PMC Policies": [
        {
            menuName: "1. Number of policies and guidelines approved by PMC, addressing  the needs of ITCs",
            menuRouteIndex: 13,
            dataFrequency: "Half Yearly"
        },
        {
            menuName: "2. Compoment and features incorporated in department design",
            menuRouteIndex: 14,
            dataFrequency: "Half Yearly"
        },
        {
            menuName: "3. Compoment and features incorporated in city planning",
            menuRouteIndex: 15,
            dataFrequency: "Half Yearly"
        }
    ],
    "F. Urban95 Budgeting": [
        {
            menuName: "1. Budget Allocation, Utilization and Budget Required",
            menuRouteIndex: 16,
            dataFrequency: "Quarterly"
        },
        // {
        //     menuName: "2. Utilization of Budget for Urban 95 Initiatives  (For reporting Quarter)",
        //     menuRouteIndex: 17,
        //     dataFrequency: "Quarterly"
        // },
        // {
        //     menuName: "3. Required amount for maintenance",
        //     menuRouteIndex: 18,
        //     dataFrequency: "Quarterly"
        // }
    ],
    "G. Status of Air and Noise pollution Near ITC Friendly Facilities": [
        {
            menuName: "1. Air and Noise Pollution",
            menuRouteIndex: 19,
            dataFrequency: "Monthly"
        },
        // {
        //     menuName: "2. Air Pollution",
        //     menuRouteIndex: 20,
        //     dataFrequency: "Monthly"
        // }
    ],
    "H. Management": [
        {
            menuName: "1. Management",
            menuRouteIndex: 21,
            dataFrequency: "Monthly"
        }
    ]
}

const allEnglishRoutes = {
    ...allRoutes
}


const allMarathiRoutes = {
    "A. आ.टी.सी स्नेही सुविधेच्या वापराचे प्रमाण": [
        {
            menuName: "1. सुविधांचा वापर केलेल्या  सांभाळकर्त्यांची संख्या -आई, वडील, आजी-आजोबा ",
            menuRouteIndex: 0,
            dataFrequency: "Monthly",
            images: true,
        },
        {
            menuName: "2. सांभाळकर्त्यांनी सुविधेमध्ये व्यतित केलेला एकूण वेळ",
            menuRouteIndex: 1,
            dataFrequency: "Monthly",
            images: true,
        },
        {
            menuName: "3. सांभाळकर्त्यांनी सुविधेमध्ये व्यतित केलेला सरासरी  वेळ ",
            menuRouteIndex: 2,
            dataFrequency: "Monthly",
            images: true,
        },
    ],
    "B. सुविधेची  सुरक्षितता": [
        {
            menuName: "1. सुविधेमधील सुरक्षेचा दर्जा",
            menuRouteIndex: 3,
            dataFrequency: "Quarterly"
        },
        {
            menuName: "2. सुविधेला भेट देण्यासाठी वापरण्यात आलेल्या प्रवासाचे साधन/मार्ग",
            menuRouteIndex: 4,
            dataFrequency: "Quarterly"
        }
    ],
    "C. उपकरणांच्या देखभालीची स्थिती": [
        {
            menuName: "1. उपलब्ध आणि कार्यक्षम उपकरणे आणि उपकरणांचे बजेट",
            menuRouteIndex: 5,
            dataFrequency: "Quarterly",
            images: true,
        },
        // {
        //     menuName: "2. कार्यरत उपकरणांची संख्या",
        //     menuRouteIndex: 6,
        //     dataFrequency: "Quarterly",
        //     images: true,
        // },
        // {
        //     menuName: "3. उपकरणांची किंमत (खरेदीच्या वेळी)",
        //     menuRouteIndex: 7,
        //     dataFrequency: "Quarterly",
        //     images: true,
        // },
        // {
        //     menuName: "2. उपकरणांच्या देखभालासाठी आवश्यक खर्च",
        //     menuRouteIndex: 8,
        //     dataFrequency: "Quarterly",
        //     images: true,
        // }
    ],
    "D. प्रशिक्षण आणि वर्तनातील बदलाची स्थिती": [
        {
            menuName: "1. सेवा प्रदात्यांसाठी आयोजित प्रशिक्षणांची संख्या",
            menuRouteIndex: 9,
            dataFrequency: "Half Yearly",
            images: true,
        },
        {
            menuName: "2. सांभाळकर्त्यांसाठी आयोजित प्रशिक्षणांची संख्या ",
            menuRouteIndex: 10,
            dataFrequency: "Half Yearly",
            images: true,
        },
        {
            menuName: "3. मनपा अधिकार्‍यांसाठी आयोजित प्रशिक्षणांची संख्या",
            menuRouteIndex: 11,
            dataFrequency: "Half Yearly",
            images: true,
        },
        {
            menuName: "4. सेवा प्रदात्याच्या  वर्तनामधील  सकारात्मक बदलाचे  प्रमाण ( सुविधा वापरकर्त्याच्या अभिप्रायानुसार)",
            menuRouteIndex: 12,
            dataFrequency: "Half Yearly"
        }
    ],
    "E. अर्बन९५ उपक्रमांचे मनपा धोरणासोबत  एकत्रीकरण": [
        {
            menuName: "1. मनपा द्वारे आय.टी.सी  पूरक मंजूर धोरणांची/मार्गदर्शक तत्त्वांची संख्या",
            menuRouteIndex: 13,
            dataFrequency: "Half Yearly"
        },
        {
            menuName: "2. मनपा विभागाच्या आराखडयांमध्ये  समाविष्ट केलेल्या आय.टी.सी अनुकूल घटक/वैशिष्ट्यांची संख्या",
            menuRouteIndex: 14,
            dataFrequency: "Half Yearly"
        },
        {
            menuName: "3. शहर नियोजनात समाविष्ट आय.टी.सी  अनुकूल  उपाययोजनांची/उपक्रमांची  संख्या",
            menuRouteIndex: 15,
            dataFrequency: "Half Yearly"
        }
    ],
    "F. अर्बन९५ बजेट": [
        {
            menuName: "1. बजेट तरतूद, बजेट वापर, आवश्यक बजेट",
            menuRouteIndex: 16,
            dataFrequency: "Quarterly"
        },
        // {
        //     menuName: "2. अर्बन९५ कार्यक्रमासाठी खर्च केलेले बजेट. (रिपोर्टिंग तिमाहीसाठी)",
        //     menuRouteIndex: 17,
        //     dataFrequency: "Quarterly"
        // },
        // {
        //     menuName: "3. अर्बन९५ कार्यक्रमासाठी आवश्यक बजेट .  (पुढील तिमाहीसाठी)",
        //     menuRouteIndex: 18,
        //     dataFrequency: "Quarterly"
        // }
    ],
    "G. आय.टी.सी अनुकूल सुविधांच्या आवारातील  ध्वनी आणि हवा प्रदूषणाची स्थिती": [
        {
            menuName: "1.ध्वनी आणि हवा प्रदूषण",
            menuRouteIndex: 19,
            dataFrequency: "Monthly"
        },
        // {
        //     menuName: "2. हवा प्रदूषण",
        //     menuRouteIndex: 20,
        //     dataFrequency: "Monthly"
        // }
    ],
    "H. व्यवस्थापन": [
        {
            menuName: "1. व्यवस्थापन",
            menuRouteIndex: 21,
            dataFrequency: "Monthly"
        }
    ]
}


export const nAllRoutes = {
    "English": {
        ...allEnglishRoutes
    },
    "Marathi": {
        ...allMarathiRoutes
    }
}

export default allRoutes