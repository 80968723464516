import { useContext } from "react";
import { AnalyticsContext } from "../../store/analytics";
import Loading from "../common/Loading";
import Constants from "../../constant/Constant";
import NormalGraph from "../graph/NormalGraph";

const TransportScreen = () => {
  const analyticsContext = useContext(AnalyticsContext);

  if (
    analyticsContext.isTransportAnalyticsLoading ||
    !analyticsContext.isTransportAnalyticsLoaded
  ) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <div style={{  }}>
      <NormalGraph
        analytics={analyticsContext.transportAnalytics}
        isBar={analyticsContext.isBar}
        isPark={analyticsContext.isPark}
        isSpan={analyticsContext.isSpan}
        isPercentage={analyticsContext.isPercentage}
        onToggleBar={() => {
          analyticsContext.onToggleBar();
        }}
        onTogglePark={() => {
          analyticsContext.onTogglePark();
        }}
        onToggleSpan={() => {
          analyticsContext.onToggleSpan();
        }}
        onTogglePercentage={() => {
          analyticsContext.onTogglePercentage()
        }}
        title={"Transport Analytics"}
        allOptions={Constants.transportAnalyticsOptions}
        isStacked={analyticsContext.isStacked}
        onToggleStack={() => {
          analyticsContext.onToggleStack();
        }}
      />
    </div>
  );
};

export default TransportScreen
