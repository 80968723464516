import { Stack, Typography } from "@mui/material";

const SmallHeaderBox = ({ primaryTitle, secondaryTitle, isLogo }) => {
  return (
    <Stack
      direction={"row"}
      spacing={isLogo ? "20px" : "10px"}
      sx={{
        background: "#fff",
        width: "220px",
        margin: "10px 10px",
        padding: "20px 20px",
        borderRadius: "10px",
        alignItems:"center",
        border:"1px solid #f6f6f6",
        boxShadow:"2px 2px 10px rgba(0,0,0,0.26)"
      }}
    >
      <Stack>
        {isLogo ? (
          <>
            <img
              style={{
                width: "40px",
                clipPath: "circle()",
              }}
              alt="PMC Care"
              src="../../assets/PMC.jpeg"
            />
          </>
        ) : (
          <></>
        )}
      </Stack>
      <Stack direction={"column"} spacing={"4px"}>
        <Typography variant="h5" sx={{ fontWeight: "700",fontSize:"14px" }}>
          {primaryTitle}
        </Typography>
        <Typography color={"secondary"} variant="h6" sx={{ fontWeight: "500",fontSize:"10px" }}>
          {secondaryTitle}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SmallHeaderBox;
