
const spansFinder = (analytics) => {

    const parks = Object.keys(analytics)

    let aParts = []

    for (let i = 0; i < parks.length; i++) {
        let years = Object.keys(analytics[parks[i]])
        for (let j = 0; j < years.length; j++) {
            const parts = Object.keys(analytics[parks[i]][years[j]])
            parts.forEach(part => {
                if (!Boolean(aParts.find(a => {
                    return a.year === years[j] && a.part === part
                }))) {
                    aParts.push({
                        year: years[j],
                        part: part
                    })
                }
            })
        }
    }

    return aParts

}

export default spansFinder