
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "./auth";
import axios from "axios"
import GetAnalytics from "../utils/GetAnalytics";
import Constants from "../constant/Constant";
import findBodyData from "../utils/findBodyData";


export const AnalyticsContext = createContext({

    visitorAnalytics: {},
    visitorAnalyticsPreviousCumulative: {},
    isVisitorAnalyticsLoaded: false,
    isVisitorAnalyticsLoading: false,
    visitorAnalyticsConf: "",


    safetyAnalytics: {},
    safetyAnalyticsPreviousCumulative: {},
    isSafetyAnalyticsLoaded: false,
    isSafetyAnalyticsLoading: false,
    safetyAnalyticsConf: "",

    transportAnalytics: {},
    transportAnalyticsPreviousCumulative: {},
    isTransportAnalyticsLoaded: false,
    isTransportAnalyticsLoading: false,
    transportAnalyticsConf: "",


    equipmentAnalytics: {},
    equipmentAnalyticsPreviousCumulative: {},
    isEquipmentAnalyticsLoaded: false,
    isEquipmentAnalyticsLoading: false,
    equipmentAnalyticsConf: "",


    serviceCapacityBuildingAnalytics: {},
    serviceAnalyticsPreviousCumulative: {},
    isServiceCapacityBuildingAnalyticsLoaded: false,
    isServiceCapacityBuildingAnalyticsLoading: false,
    serviceCapacityBuildingAnalyticsConf: "",

    caregiversCapacityBuildingAnalytics: {},
    caregiversAnalyticsPreviousCumulative: {},
    isCaregiversCapacityBuildingAnalyticsLoaded: false,
    isCaregiversCapacityBuildingAnalyticsLoading: false,
    caregiversCapacityBuildingAnalyticsConf: "",


    pmcCapacityBuildingAnalytics: {},
    pmcAnalyticsPreviousCumulative: {},
    isPmcCapacityBuildingAnalyticsLoaded: false,
    isPmcCapacityBuildingAnalyticsLoading: false,
    pmcCapacityBuildingAnalyticsConf: "",


    serviceProviderFeedbackAnalytics: {},
    serviceProviderFeedbackAnalyticsPreviousCumulative: {},
    isServiceProviderFeedbackAnalyticsLoaded: false,
    isServiceProviderFeedbackAnalyticsLoading: false,
    serviceProviderFeedbackAnalyticsConf: "",


    guideLines1Analytics: {},
    guideLines1AnalyticsPreviousCumulative: {},
    isGuideLines1AnalyticsLoaded: false,
    isGuideLines1AnalyticsLoading: false,
    guideLines1AnalyticsConf: "",


    guideLines2Analytics: {},
    guideLines2AnalyticsPreviousCumulative: {},
    isGuideLines2AnalyticsLoaded: false,
    isGuideLines2AnalyticsLoading: false,
    guideLines2AnalyticsConf: "",

    guideLines3Analytics: {},
    guideLines3AnalyticsPreviousCumulative: {},
    isGuideLines3AnalyticsLoaded: false,
    isGuideLines3AnalyticsLoading: false,
    guideLines3AnalyticsConf: "",


    budgetAnalytics: {},
    budgetAnalyticsPreviousCumulative: {},
    isBudgetAnalyticsLoaded: false,
    isBudgetAnalyticsLoading: false,
    budgetAnalyticsConf: "",


    pollutionAnalytics: {},
    pollutionAnalyticsPreviousCumulative: {},
    isPollutionAnalyticsLoaded: false,
    isPollutionAnalyticsLoading: false,
    pollutionAnalyticsConf: "",



    startDay: "01-01-2024",
    days: 366,
    onUpdateDate: (date, days) => { },


    isError: false,
    errorMessage: "",
    clearMessage: () => { },


    isBar: false,
    isPark: false,
    isSpan: false,
    isPercentage: false,
    isAverage: false,
    isStacked: true,

    onTogglePercentage: () => { },
    onToggleAverage: () => { },
    onToggleBar: () => { },
    onTogglePark: () => { },
    onToggleSpan: () => { },
    onToggleStack: () => { },
})


const AnalyticsContextProvider = ({ children, menuIndex }) => {

    const authContext = useContext(AuthContext)

    const [analyticsData, setAnalyticsData] = useState({

        visitorAnalytics: {},
        visitorAnalyticsPreviousCumulative: {},
        isVisitorAnalyticsLoaded: false,
        isVisitorAnalyticsLoading: false,
        visitorAnalyticsConf: "",


        safetyAnalytics: {},
        safetyAnalyticsPreviousCumulative: {},
        isSafetyAnalyticsLoaded: false,
        isSafetyAnalyticsLoading: false,
        safetyAnalyticsConf: "",

        transportAnalytics: {},
        transportAnalyticsPreviousCumulative: {},
        isTransportAnalyticsLoaded: false,
        isTransportAnalyticsLoading: false,
        transportAnalyticsConf: "",


        equipmentAnalytics: {},
        equipmentAnalyticsPreviousCumulative: {},
        isEquipmentAnalyticsLoaded: false,
        isEquipmentAnalyticsLoading: false,
        equipmentAnalyticsConf: "",


        serviceCapacityBuildingAnalytics: {},
        serviceAnalyticsPreviousCumulative: {},
        isServiceCapacityBuildingAnalyticsLoaded: false,
        isServiceCapacityBuildingAnalyticsLoading: false,
        serviceCapacityBuildingAnalyticsConf: "",

        caregiversCapacityBuildingAnalytics: {},
        caregiversAnalyticsPreviousCumulative: {},
        isCaregiversCapacityBuildingAnalyticsLoaded: false,
        isCaregiversCapacityBuildingAnalyticsLoading: false,
        caregiversCapacityBuildingAnalyticsConf: "",


        pmcCapacityBuildingAnalytics: {},
        pmcAnalyticsPreviousCumulative: {},
        isPmcCapacityBuildingAnalyticsLoaded: false,
        isPmcCapacityBuildingAnalyticsLoading: false,
        pmcCapacityBuildingAnalyticsConf: "",


        guideLines1Analytics: {},
        guideLines1AnalyticsPreviousCumulative: {},
        isGuideLines1AnalyticsLoaded: false,
        isGuideLines1AnalyticsLoading: false,
        guideLines1AnalyticsConf: "",


        guideLines2Analytics: {},
        guideLines2AnalyticsPreviousCumulative: {},
        isGuideLines2AnalyticsLoaded: false,
        isGuideLines2AnalyticsLoading: false,
        guideLines2AnalyticsConf: "",

        guideLines3Analytics: {},
        guideLines3AnalyticsPreviousCumulative: {},
        isGuideLines3AnalyticsLoaded: false,
        isGuideLines3AnalyticsLoading: false,
        guideLines3AnalyticsConf: "",


        budgetAnalytics: {},
        budgetAnalyticsPreviousCumulative: {},
        isBudgetAnalyticsLoaded: false,
        isBudgetAnalyticsLoading: false,
        budgetAnalyticsConf: "",


        pollutionAnalytics: {},
        pollutionAnalyticsPreviousCumulative: {},
        isPollutionAnalyticsLoaded: false,
        isPollutionAnalyticsLoading: false,
        pollutionAnalyticsConf: "",


        startDay: "01-01-2024",
        days: 366,


        isBar: Boolean(window.localStorage.getItem("PMCisBar") === "true"),
        isPark: Boolean(window.localStorage.getItem("PMCisPark") === "true"),
        isSpan: Boolean(window.localStorage.getItem("PMCisSpan") === "true"),
        isPercentage: Boolean(window.localStorage.getItem("PMCisPercentage") === "true"),
        isAverage: Boolean(window.localStorage.getItem("PMCisAverage") === "true"),
        isStacked: Boolean(window.localStorage.getItem("PMCisStacked") === "true"),

        isError: false,
        errorMessage: "",
    })






    const visitorAnalyticsToken = useRef(null)
    const budgetAnalyticsToken = useRef(null)
    const pollutionAnalyticsToken = useRef(null)
    const safetyAnalyticsToken = useRef(null)
    const transportAnalyticsToken = useRef(null)
    const equipmentAnalyticsToken = useRef(null)
    const guideLines1AnalyticsToken = useRef(null)
    const guideLines2AnalyticsToken = useRef(null)
    const guideLines3AnalyticsToken = useRef(null)
    const serviceCapacityBuildingAnalyticsToken = useRef(null)
    const caregiversCapacityBuildingAnalyticsToken = useRef(null)
    const pmcCapacityBuildingAnalyticsToken = useRef(null)
    const serviceProviderFeedbackAnalyticsToken = useRef(null)



    const loadVisitorAnalytics = useCallback(async (startDay, days, conf) => {
        if (conf === `${startDay}${days}`) {
            return
        }
        if (visitorAnalyticsToken.current && visitorAnalyticsToken.current.token) {
            visitorAnalyticsToken.current.cancel()
        }

        visitorAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isVisitorAnalyticsLoaded: false, isVisitorAnalyticsLoading: true, visitorAnalytics: {}, visitorAnalyticsPreviousCumulative: {} } })

        try {
            const data = await GetAnalytics(Constants.visitorAnalyticsRoute, authContext.authToken, findBodyData(startDay, days, "mon"), visitorAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isVisitorAnalyticsLoading: false,
                        isVisitorAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isVisitorAnalyticsLoading: false,
                        isVisitorAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Visitor Analytics Updated Successfully.",
                        visitorAnalytics: data.data,
                        visitorAnalyticsConf: `${startDay}${days}`,
                        visitorAnalyticsPreviousCumulative: data.cumulative
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )


    const loadSafetyAnalytics = useCallback(async (startDay, days, conf) => {
        if (conf === `${startDay}${days}`) {
            return
        }
        if (safetyAnalyticsToken.current && safetyAnalyticsToken.current.token) {
            safetyAnalyticsToken.current.cancel()
        }

        safetyAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isSafetyAnalyticsLoaded: false, isSafetyAnalyticsLoading: true, safetyAnalytics: {}, safetyAnalyticsPreviousCumulative: {} } })

        try {
            const data = await GetAnalytics(Constants.safetyAnalyticsRoute, authContext.authToken, findBodyData(startDay, days, "quart"), safetyAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isSafetyAnalyticsLoading: false,
                        isSafetyAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isSafetyAnalyticsLoading: false,
                        isSafetyAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Safety Analytics Updated Successfully.",
                        safetyAnalytics: data.data,
                        safetyAnalyticsConf: `${startDay}${days}`,
                        safetyAnalyticsPreviousCumulative: data.cumulative
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )


    const loadTransportAnalytics = useCallback(async (startDay, days, conf) => {
        if (conf === `${startDay}${days}`) {
            return
        }
        if (transportAnalyticsToken.current && transportAnalyticsToken.current.token) {
            transportAnalyticsToken.current.cancel()
        }

        transportAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isTransportAnalyticsLoaded: false, isTransportAnalyticsLoading: true, transportAnalytics: {}, transportAnalyticsPreviousCumulative: {} } })

        try {
            const data = await GetAnalytics(Constants.transportAnalyticsRoute, authContext.authToken, findBodyData(startDay, days, "quart"), transportAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isTransportAnalyticsLoading: false,
                        isTransportAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isTransportAnalyticsLoading: false,
                        isTransportAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Transport Analytics Updated Successfully.",
                        transportAnalytics: data.data,
                        transportAnalyticsConf: `${startDay}${days}`,
                        transportAnalyticsPreviousCumulative: data.cumulative
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )


    const loadEquipmentsAnalytics = useCallback(async (startDay, days, conf) => {
        if (conf === `${startDay}${days}`) {
            return
        }
        if (equipmentAnalyticsToken.current && equipmentAnalyticsToken.current.token) {
            equipmentAnalyticsToken.current.cancel()
        }

        equipmentAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isEquipmentAnalyticsLoaded: false, isEquipmentAnalyticsLoading: true, equipmentAnalytics: {}, equipmentAnalyticsPreviousCumulative: {} } })

        try {
            const data = await GetAnalytics(Constants.equipmentAnalyticsRoute, authContext.authToken, findBodyData(startDay, days, "quart"), equipmentAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isEquipmentAnalyticsLoading: false,
                        isEquipmentAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isEquipmentAnalyticsLoading: false,
                        isEquipmentAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Equipment Analytics Updated Successfully.",
                        equipmentAnalytics: data.data,
                        equipmentAnalyticsConf: `${startDay}${days}`,
                        equipmentAnalyticsPreviousCumulative: data.cumulative
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )



    const loadServiceCapacityBuildingAnalytics = useCallback(async (startDay, days, conf) => {
        if (conf === `${startDay}${days}`) {
            return
        }
        if (serviceCapacityBuildingAnalyticsToken.current && serviceCapacityBuildingAnalyticsToken.current.token) {
            serviceCapacityBuildingAnalyticsToken.current.cancel()
        }

        serviceCapacityBuildingAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isServiceCapacityBuildingAnalyticsLoaded: false, isServiceCapacityBuildingAnalyticsLoading: true, serviceCapacityBuildingAnalytics: {}, serviceAnalyticsPreviousCumulative: {} } })

        try {
            const data = await GetAnalytics(Constants.serviceCapacityBuildingRoute, authContext.authToken, findBodyData(startDay, days, "term"), serviceCapacityBuildingAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isServiceCapacityBuildingAnalyticsLoading: false,
                        isServiceCapacityBuildingAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isServiceCapacityBuildingAnalyticsLoading: false,
                        isServiceCapacityBuildingAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Capacity Building Analytics Updated Successfully.",
                        serviceCapacityBuildingAnalytics: data.data,
                        serviceCapacityBuildingAnalyticsConf: `${startDay}${days}`,
                        serviceAnalyticsPreviousCumulative: data.cumulative
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )

    const loadCaregiversCapacityBuildingAnalytics = useCallback(async (startDay, days, conf) => {
        if (conf === `${startDay}${days}`) {
            return
        }
        if (caregiversCapacityBuildingAnalyticsToken.current && caregiversCapacityBuildingAnalyticsToken.current.token) {
            caregiversCapacityBuildingAnalyticsToken.current.cancel()
        }

        caregiversCapacityBuildingAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isCaregiversCapacityBuildingAnalyticsLoaded: false, isCaregiversCapacityBuildingAnalyticsLoading: true, caregiversCapacityBuildingAnalytics: {}, caregiversAnalyticsPreviousCumulative: {} } })

        try {
            const data = await GetAnalytics(Constants.caregiversCapacityBuildingRoute, authContext.authToken, findBodyData(startDay, days, "term"), caregiversCapacityBuildingAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isCaregiversCapacityBuildingAnalyticsLoading: false,
                        isCaregiversCapacityBuildingAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isCaregiversCapacityBuildingAnalyticsLoading: false,
                        isCaregiversCapacityBuildingAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Capacity Building Analytics Updated Successfully.",
                        caregiversCapacityBuildingAnalytics: data.data,
                        caregiversCapacityBuildingAnalyticsConf: `${startDay}${days}`,
                        caregiversAnalyticsPreviousCumulative: data.cumulative
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )


    const loadPmcCapacityBuildingAnalytics = useCallback(async (startDay, days, conf) => {
        if (conf === `${startDay}${days}`) {
            return
        }
        if (pmcCapacityBuildingAnalyticsToken.current && pmcCapacityBuildingAnalyticsToken.current.token) {
            pmcCapacityBuildingAnalyticsToken.current.cancel()
        }

        pmcCapacityBuildingAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isPmcCapacityBuildingAnalyticsLoaded: false, isPmcCapacityBuildingAnalyticsLoading: true, pmcCapacityBuildingAnalytics: {}, pmcAnalyticsPreviousCumulative: {} } })

        try {
            const data = await GetAnalytics(Constants.pmcCapacityBuildingRoute, authContext.authToken, findBodyData(startDay, days, "term"), pmcCapacityBuildingAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isPmcCapacityBuildingAnalyticsLoading: false,
                        isPmcCapacityBuildingAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isPmcCapacityBuildingAnalyticsLoading: false,
                        isPmcCapacityBuildingAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Capacity Building Analytics Updated Successfully.",
                        pmcCapacityBuildingAnalytics: data.data,
                        pmcCapacityBuildingAnalyticsConf: `${startDay}${days}`,
                        pmcAnalyticsPreviousCumulative: data.cumulative
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )


    const loadServiceProviderFeedbackAnalytics = useCallback(async (startDay, days, conf) => {
        if (conf === `${startDay}${days}`) {
            return
        }
        if (serviceProviderFeedbackAnalyticsToken.current && serviceProviderFeedbackAnalyticsToken.current.token) {
            serviceProviderFeedbackAnalyticsToken.current.cancel()
        }

        serviceProviderFeedbackAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isServiceProviderFeedbackAnalyticsLoaded: false, isServiceProviderFeedbackAnalyticsLoading: true, serviceProviderFeedbackAnalytics: {}, serviceProviderFeedbackAnalyticsPreviousCumulative: {} } })

        try {
            const data = await GetAnalytics(Constants.serviceProviderFeedbackAnalyticRoute, authContext.authToken, findBodyData(startDay, days, "term"), serviceProviderFeedbackAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isServiceProviderFeedbackAnalyticsLoading: false,
                        isServiceProviderFeedbackAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isServiceProviderFeedbackAnalyticsLoading: false,
                        isServiceProviderFeedbackAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Service Provider Feedback Analytics Updated Successfully.",
                        serviceProviderFeedbackAnalytics: data.data,
                        serviceProviderFeedbackAnalyticsConf: `${startDay}${days}`,
                        serviceProviderFeedbackAnalyticsPreviousCumulative: data.cumulative
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )


    const loadGuidelines1Analytics = useCallback(async (startDay, days, conf) => {
        if (conf === `${startDay}${days}`) {
            return
        }
        if (guideLines1AnalyticsToken.current && guideLines1AnalyticsToken.current.token) {
            guideLines1AnalyticsToken.current.cancel()
        }

        guideLines1AnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isGuideLines1AnalyticsLoaded: false, isGuideLines1AnalyticsLoading: true, guideLines1Analytics: {}, guideLines1AnalyticsPreviousCumulative: {} } })

        try {
            const data = await GetAnalytics(Constants.guideLines1AnalyticsRoute, authContext.authToken, findBodyData(startDay, days, "term"), guideLines1AnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isGuideLines1AnalyticsLoading: false,
                        isGuideLines1AnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isGuideLines1AnalyticsLoading: false,
                        isGuideLines1AnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Guidelines Analytics Updated Successfully.",
                        guideLines1Analytics: data.data,
                        guideLines1AnalyticsConf: `${startDay}${days}`,
                        guideLines1AnalyticsPreviousCumulative: data.cumulative
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )

    const loadGuidelines2Analytics = useCallback(async (startDay, days, conf) => {
        if (conf === `${startDay}${days}`) {
            return
        }
        if (guideLines2AnalyticsToken.current && guideLines2AnalyticsToken.current.token) {
            guideLines2AnalyticsToken.current.cancel()
        }

        guideLines2AnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isGuideLines2AnalyticsLoaded: false, isGuideLines2AnalyticsLoading: true, guideLines2Analytics: {}, guideLines2AnalyticsPreviousCumulative: {} } })

        try {
            const data = await GetAnalytics(Constants.guideLines2AnalyticsRoute, authContext.authToken, findBodyData(startDay, days, "term"), guideLines2AnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isGuideLines2AnalyticsLoading: false,
                        isGuideLines2AnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isGuideLines2AnalyticsLoading: false,
                        isGuideLines2AnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Guidelines Analytics Updated Successfully.",
                        guideLines2Analytics: data.data,
                        guideLines2AnalyticsConf: `${startDay}${days}`,
                        guideLines2AnalyticsPreviousCumulative: data.cumulative
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )

    const loadGuidelines3Analytics = useCallback(async (startDay, days, conf) => {
        if (conf === `${startDay}${days}`) {
            return
        }
        if (guideLines3AnalyticsToken.current && guideLines3AnalyticsToken.current.token) {
            guideLines3AnalyticsToken.current.cancel()
        }

        guideLines3AnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isGuideLines3AnalyticsLoaded: false, isGuideLines3AnalyticsLoading: true, guideLines3Analytics: {}, guideLines3AnalyticsPreviousCumulative: {} } })

        try {
            const data = await GetAnalytics(Constants.guideLines3AnalyticsRoute, authContext.authToken, findBodyData(startDay, days, "term"), guideLines3AnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isGuideLines3AnalyticsLoading: false,
                        isGuideLines3AnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isGuideLines3AnalyticsLoading: false,
                        isGuideLines3AnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Guidelines Analytics Updated Successfully.",
                        guideLines3Analytics: data.data,
                        guideLines3AnalyticsConf: `${startDay}${days}`,
                        guideLines3AnalyticsPreviousCumulative: data.cumulative
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )

    const loadBudgetAnalytics = useCallback(async (startDay, days, conf) => {
        if (conf === `${startDay}${days}`) {
            return
        }
        if (budgetAnalyticsToken.current && budgetAnalyticsToken.current.token) {
            budgetAnalyticsToken.current.cancel()
        }

        budgetAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isBudgetAnalyticsLoaded: false, isBudgetAnalyticsLoading: true, budgetAnalytics: {}, budgetAnalyticsPreviousCumulative: {} } })

        try {
            const data = await GetAnalytics(Constants.budgetAnalyticsRoute, authContext.authToken, findBodyData(startDay, days, "quart"), budgetAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isBudgetAnalyticsLoading: false,
                        isBudgetAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isBudgetAnalyticsLoading: false,
                        isBudgetAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Budget Analytics Updated Successfully.",
                        budgetAnalytics: data.data,
                        budgetAnalyticsConf: `${startDay}${days}`,
                        budgetAnalyticsPreviousCumulative: data.cumulative
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )


    const loadPollutionAnalytics = useCallback(async (startDay, days, conf) => {
        if (conf === `${startDay}${days}`) {
            return
        }
        if (pollutionAnalyticsToken.current && pollutionAnalyticsToken.current.token) {
            pollutionAnalyticsToken.current.cancel()
        }

        pollutionAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isPollutionAnalyticsLoaded: false, isPollutionAnalyticsLoading: true, pollutionAnalytics: {}, pollutionAnalyticsPreviousCumulative: {} } })

        try {
            const data = await GetAnalytics(Constants.pollutionAnalyticsRoute, authContext.authToken, findBodyData(startDay, days, "mon"), pollutionAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isPollutionAnalyticsLoading: false,
                        isPollutionAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isPollutionAnalyticsLoading: false,
                        isPollutionAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Pollution Analytics Updated Successfully.",
                        pollutionAnalytics: data.data,
                        pollutionAnalyticsConf: `${startDay}${days}`,
                        pollutionAnalyticsPreviousCumulative: data.cumulative
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }

    },
        [
            authContext.authToken
        ]
    )


    const onToggleBar = useCallback(() => {
        setAnalyticsData(p => {
            window.localStorage.setItem("PMCisBar", !p.isBar)
            return {
                ...p,
                isBar: !p.isBar
            }
        })
    }, [])

    const onToggleStack = useCallback(() => {
        setAnalyticsData(p => {
            window.localStorage.setItem("PMCisStacked", !p.isStacked)
            return {
                ...p,
                isStacked: !p.isStacked
            }
        })
    }, [])


    const onTogglePark = useCallback(() => {
        setAnalyticsData(p => {
            window.localStorage.setItem("PMCisPark", !p.isPark)
            return {
                ...p,
                isPark: !p.isPark
            }
        })
    }, [])

    const onToggleSpan = useCallback(() => {

        setAnalyticsData(p => {
            window.localStorage.setItem("PMCisSpan", !p.isSpan)
            return {
                ...p,
                isSpan: !p.isSpan
            }
        })
    }, [])

    const onTogglePercentage = useCallback(() => {

        setAnalyticsData(p => {
            window.localStorage.setItem("PMCisPercentage", !p.isPercentage)
            return {
                ...p,
                isPercentage: !p.isPercentage
            }
        })
    }, [])

    const onToggleAverage = useCallback(() => {

        setAnalyticsData(p => {
            window.localStorage.setItem("PMCisAverage", !p.isAverage)
            return {
                ...p,
                isAverage: !p.isAverage
            }
        })
    }, [])


    const onUpdateDate = (date, days) => {
        setAnalyticsData(p => {
            return {
                ...p,
                startDay: date,
                days: days,
                isChanged: true,
                isBookingSalesChanged: true
            }
        })
    }

    const clearMessage = () => {
        setAnalyticsData(prevState => {
            return {
                ...prevState,
                isError: false,
                errorMessage: "",
            }
        })
    }

    const value = {
        ...analyticsData,
        onUpdateDate,
        clearMessage,
        onTogglePark,
        onToggleBar,
        onToggleAverage,
        onTogglePercentage,
        onToggleSpan,
        onToggleStack
    }


    useEffect(() => {
        if ([0, 1, 2].includes(menuIndex)) {
            loadVisitorAnalytics(analyticsData.startDay, analyticsData.days, analyticsData.visitorAnalyticsConf)
        }
    },
        [
            analyticsData.startDay,
            analyticsData.days,
            loadVisitorAnalytics,
            analyticsData.visitorAnalyticsConf,
            menuIndex
        ]
    )


    useEffect(() => {
        if ([3].includes(menuIndex)) {
            loadSafetyAnalytics(analyticsData.startDay, analyticsData.days, analyticsData.safetyAnalyticsConf)
        }
    },
        [
            analyticsData.startDay,
            analyticsData.days,
            loadSafetyAnalytics,
            analyticsData.safetyAnalyticsConf,
            menuIndex
        ]
    )

    useEffect(() => {
        if ([4].includes(menuIndex)) {
            loadTransportAnalytics(analyticsData.startDay, analyticsData.days, analyticsData.transportAnalyticsConf)
        }
    },
        [
            analyticsData.startDay,
            analyticsData.days,
            loadTransportAnalytics,
            analyticsData.transportAnalyticsConf,
            menuIndex
        ]
    )

    useEffect(() => {
        if ([5, 6, 7,8].includes(menuIndex)) {
            loadEquipmentsAnalytics(analyticsData.startDay, analyticsData.days, analyticsData.equipmentAnalyticsConf)
        }
    },
        [
            analyticsData.startDay,
            analyticsData.days,
            loadEquipmentsAnalytics,
            analyticsData.equipmentAnalyticsConf,
            menuIndex
        ]
    )


    useEffect(() => {
        if ([9].includes(menuIndex)) {
            loadServiceCapacityBuildingAnalytics(analyticsData.startDay, analyticsData.days, analyticsData.serviceCapacityBuildingAnalyticsConf)
        }
    },
        [
            analyticsData.startDay,
            analyticsData.days,
            loadServiceCapacityBuildingAnalytics,
            analyticsData.serviceCapacityBuildingAnalyticsConf,
            menuIndex
        ]
    )


    useEffect(() => {
        if ([10].includes(menuIndex)) {
            loadCaregiversCapacityBuildingAnalytics(analyticsData.startDay, analyticsData.days, analyticsData.caregiversCapacityBuildingAnalyticsConf)
        }
    },
        [
            analyticsData.startDay,
            analyticsData.days,
            loadCaregiversCapacityBuildingAnalytics,
            analyticsData.caregiversCapacityBuildingAnalyticsConf,
            menuIndex
        ]
    )

    useEffect(() => {
        if ([11].includes(menuIndex)) {
            loadPmcCapacityBuildingAnalytics(analyticsData.startDay, analyticsData.days, analyticsData.pmcCapacityBuildingAnalyticsConf)
        }
    },
        [
            analyticsData.startDay,
            analyticsData.days,
            loadPmcCapacityBuildingAnalytics,
            analyticsData.pmcCapacityBuildingAnalyticsConf,
            menuIndex
        ]
    )

    useEffect(() => {
        if ([12].includes(menuIndex)) {
            loadServiceProviderFeedbackAnalytics(analyticsData.startDay, analyticsData.days, analyticsData.serviceProviderFeedbackAnalyticsConf)
        }
    },
        [
            analyticsData.startDay,
            analyticsData.days,
            loadServiceProviderFeedbackAnalytics,
            analyticsData.serviceProviderFeedbackAnalyticsConf,
            menuIndex
        ]
    )



    useEffect(() => {
        if ([13].includes(menuIndex)) {
            loadGuidelines1Analytics(analyticsData.startDay, analyticsData.days, analyticsData.guideLines1AnalyticsConf)
        }
    },
        [
            analyticsData.startDay,
            analyticsData.days,
            loadGuidelines1Analytics,
            analyticsData.guideLines1AnalyticsConf,
            menuIndex
        ]
    )



    useEffect(() => {
        if ([14].includes(menuIndex)) {
            loadGuidelines2Analytics(analyticsData.startDay, analyticsData.days, analyticsData.guideLines2AnalyticsConf)
        }
    },
        [
            analyticsData.startDay,
            analyticsData.days,
            loadGuidelines2Analytics,
            analyticsData.guideLines2AnalyticsConf,
            menuIndex
        ]
    )


    useEffect(() => {
        if ([15].includes(menuIndex)) {
            loadGuidelines3Analytics(analyticsData.startDay, analyticsData.days, analyticsData.guideLines3AnalyticsConf)
        }
    },
        [
            analyticsData.startDay,
            analyticsData.days,
            loadGuidelines3Analytics,
            analyticsData.guideLines3AnalyticsConf,
            menuIndex
        ]
    )


    useEffect(() => {
        if ([16,17,18].includes(menuIndex)) {
            loadBudgetAnalytics(analyticsData.startDay, analyticsData.days, analyticsData.budgetAnalyticsConf)
        }
    },
        [
            analyticsData.startDay,
            analyticsData.days,
            loadBudgetAnalytics,
            analyticsData.budgetAnalyticsConf,
            menuIndex
        ]
    )


    useEffect(() => {
        if ([19,20].includes(menuIndex)) {
            loadPollutionAnalytics(analyticsData.startDay, analyticsData.days, analyticsData.pollutionAnalyticsConf)
        }
    },
        [
            analyticsData.startDay,
            analyticsData.days,
            loadPollutionAnalytics,
            analyticsData.pollutionAnalyticsConf,
            menuIndex
        ]
    )

    return (
        <AnalyticsContext.Provider value={value}>
            {children}
        </AnalyticsContext.Provider>
    )

}

export default AnalyticsContextProvider