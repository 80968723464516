import { useContext } from "react";
import CustomSnackBar from "../common/CustomSnackBar";
import CustomDateRangePicker from "../common/DateRangePicker";
import { AnalyticsContext } from "../../store/analytics";
import BudgetScreen from "../budgets/budgetScreen";
import PollutionScreen from "../pollution/pollutionScreen";
import SafetyScreen from "../safety/SafetyScreen";
import TransportScreen from "../transport/TransportScreen";
import EquipmentsScreen from "../equipments/EquipmentScreen";
import Constants from "../../constant/Constant";
import GuideLines1Screen from "../guideline/GuideLines1Screen";
import GuideLines2Screen from "../guideline/GuideLines2Screen";
import GuideLines3Screen from "../guideline/GuideLines3Screen";
import TimeSpentScreen from "../visitors/TimeSpentScreen";
import ServiceCapacityBuildingScreen from "../capacityBuilding/ServiceCapacityBuildingScreen";
import CaregiversCapacityBuildingScreen from "../capacityBuilding/CaregiversCapacityBuildingScreen";
import PmcCapacityBuildingScreen from "../capacityBuilding/PmcCapacityBuildingScreen";
import ServiceProviderFeedbackScreen from "../capacityBuilding/ServiceProviderFeedbackScreen";
import AverageTimeSpentGraphScreen from "../visitors/AverageTimeSpentGraphScreen";
import { Stack, Typography } from "@mui/material";
import SmallHeaderBox from "../common/SmallHeaderBox";
import { AuthContext } from "../../store/auth";
import ManagementScreen from "../management/ManagementScreen";
import VisitorScreen from "../visitors/visitorScreen";
import ManagementContextProvider from "../../store/management";

const PMCStack = ({ dataFrequency, menuIndex }) => {
  const analyticsContext = useContext(AnalyticsContext);
  const authContext = useContext(AuthContext);

  let content = <></>;

  if (menuIndex === 0) {
    content = <VisitorScreen />;
  } else if (menuIndex === 1) {
    content = <TimeSpentScreen />;
  } else if (menuIndex === 2) {
    content = <AverageTimeSpentGraphScreen />;
  } else if (menuIndex === 3) {
    content = <SafetyScreen />;
  } else if (menuIndex === 4) {
    content = <TransportScreen />;
  } else if (menuIndex === 5) {
    content = (
      <EquipmentsScreen
        extras={["Name of dysfunctional equipments", "suggestion"]}
        options={Constants.equipmentAnalyticsOptions}
      />
    );
  } else if (menuIndex === 6) {
    content = (
      <EquipmentsScreen
        extras={["Name of dysfunctional equipments", "suggestion"]}
        options={[Constants.equipmentAnalyticsOptions[1]]}
      />
    );
  } else if (menuIndex === 7) {
    content = (
      <EquipmentsScreen options={[Constants.equipmentAnalyticsOptions[2]]} />
    );
  } else if (menuIndex === 8) {
    content = (
      <EquipmentsScreen options={[Constants.equipmentAnalyticsOptions[3]]} />
    );
  } else if (menuIndex === 9) {
    content = <ServiceCapacityBuildingScreen />;
  } else if (menuIndex === 10) {
    content = <CaregiversCapacityBuildingScreen />;
  } else if (menuIndex === 11) {
    content = <PmcCapacityBuildingScreen />;
  } else if (menuIndex === 12) {
    content = <ServiceProviderFeedbackScreen />;
  } else if (menuIndex === 13) {
    content = <GuideLines1Screen />;
  } else if (menuIndex === 14) {
    content = <GuideLines2Screen />;
  } else if (menuIndex === 15) {
    content = <GuideLines3Screen />;
  } else if (menuIndex === 16 || menuIndex === 17 || menuIndex === 18) {
    content = <BudgetScreen />;
  } else if (menuIndex === 19 || menuIndex === 20) {
    content = <PollutionScreen />;
  } else if (menuIndex === 21) {
    content = authContext.isManagement ? (
      <ManagementContextProvider menuIndex={menuIndex}>
        <ManagementScreen />
      </ManagementContextProvider>
    ) : (
      <></>
    );
  }

  return (
    <>
      {menuIndex !== 21 && (
        <div
          style={{
            // marginTop: "20px",
            width: "800px",
            padding: "3px",
            position: "absolute",
            top: "15px",
            left: "30px",
            zIndex: 4,
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
            }}
          >
            <div style={{ width: "450px" }}>
              <CustomDateRangePicker
                startDay={analyticsContext.startDay}
                days={analyticsContext.days}
                onDateChange={(day, date) => {
                  analyticsContext.onUpdateDate(day, date);
                }}
              />
            </div>
            <Typography sx={{
              marginLeft:"60px",
              fontSize:"22px"
            }}>Urban95 Dashboard</Typography>
          </Stack>
        </div>
      )}

      <CustomSnackBar
        isOpen={analyticsContext.isError}
        message={analyticsContext.errorMessage}
        onClose={() => {
          analyticsContext.clearMessage();
        }}
      />
      <div style={{ marginTop: "40px" }}>
        {menuIndex !== 21 && (
          <Stack
            direction={"row"}
            style={{
              marginTop: "90px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <SmallHeaderBox
              primaryTitle={"विभाग (Department)"}
              secondaryTitle={authContext.department}
              isLogo={false}
            />
            <SmallHeaderBox
              primaryTitle={"डेटा वारंवारता (Data Frequency)"}
              secondaryTitle={dataFrequency}
            />
            <img
              style={{
                maxWidth: "calc(100% - 550px)",
              }}
              src="../../assets/2.png"
              alt="Child"
            ></img>
          </Stack>
        )}
        {content}
      </div>
    </>
  );
};

export default PMCStack;
