import { useContext } from "react";
import { AnalyticsContext } from "../../store/analytics";
import Loading from "../common/Loading";
import Constants from "../../constant/Constant";
import NormalGraph from "../graph/NormalGraph";



const BudgetScreen = () => {


    const analyticsContext = useContext(AnalyticsContext);

  if (
    analyticsContext.isBudgetAnalyticsLoading ||
    !analyticsContext.isBudgetAnalyticsLoaded
  ) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <div style={{  }}>
      <NormalGraph
        analytics={analyticsContext.budgetAnalytics}
        isBar={analyticsContext.isBar}
        isPark={analyticsContext.isPark}
        isSpan={analyticsContext.isSpan}
        isPercentage={analyticsContext.isPercentage}
        onToggleBar={() => {
          analyticsContext.onToggleBar();
        }}
        onTogglePark={() => {
          analyticsContext.onTogglePark();
        }}
        onToggleSpan={() => {
          analyticsContext.onToggleSpan();
        }}
        onTogglePercentage={() => {
          analyticsContext.onTogglePercentage()
        }}
        title={"Budget Analytics"}
        allOptions={Constants.budgetAnalyticsOptions}
        isStacked={analyticsContext.isStacked}
        onToggleStack={() => {
          analyticsContext.onToggleStack();
        }}
      />
    </div>
  );


}

export default BudgetScreen