import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import { IconButton, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const CustomSnackbarContent = styled(SnackbarContent)`
  background-color: #262626;
  color: #f6f6f6;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1rem;
  font-weight: bold;
`;

const CustomSnackBar = ({ isOpen, message, onClose }) => {

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      autoHideDuration={3000}
      onClose={() => {
        onClose();
      }}
      open={isOpen}
    >
      <CustomSnackbarContent
        message={message}
        action={
          <React.Fragment>
            <IconButton onClick={onClose}>
              <CloseIcon
                color="background.main"
                sx={{
                  color: "white",
                }}
              />
            </IconButton>
          </React.Fragment>
        }
      />
    </Snackbar>
  );
};

export default CustomSnackBar;
