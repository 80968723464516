import {
  MenuItem,
  FormControl,
  Checkbox,
  Button,
  Typography,
  Grow,
  Paper,
  MenuList,
  Popper,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

const LanguageSelector = ({ allLang, selLang, onChange, width }) => {
  const buttonRef = useRef(undefined);
  const popperRef = useRef(undefined);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <FormControl>
      <Button
        ref={buttonRef}
        sx={{
          width: width,
          border: `1px solid #fff`,
          outline: isVisible ? `1px solid #fff` : "1px solid transparent",
          fontSize: "12px",
          height: "30px",
        }}
        onClick={() => {
          setIsVisible((p) => !p);
        }}
      >
        <Typography
          noWrap
          sx={{
            padding: "5px 7px",
            textOverflow: "ellipsis",
            textTransform: "none",
            wordBreak: "break-all",
            maxLines: 1,
            color: "#fff",
          }}
          variant="h6"
          fontSize={"11px"}
          color="primary.main"
        >
          {selLang}
        </Typography>
      </Button>

      <Popper
        anchorEl={buttonRef.current}
        role={undefined}
        placement="bottom"
        open={isVisible && Boolean(buttonRef.current)}
        disablePortal={true}
        transition
        ref={popperRef}
        sx={{
          // maxWidth: "600px",
          margin: "10px",
          zIndex:100
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "center top" }}>
            <Paper
              elevation={10}
              sx={{
                marginTop: "3px",
                maxHeight: "450px",
                overflow: "scroll",
              }}
            >
              <MenuList>
                {[...allLang].map((option) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        onChange(option);
                        setIsVisible(false)
                      }}
                      key={option}
                      value={option}
                      color="primary"
                    >
                      <Checkbox checked={option === selLang} />
                      <Typography
                        sx={{
                          // textOverflow: "ellipsis",
                          maxWidth: "600px",
                          overflow: "scroll",
                          "::-webkit-scrollbar": {
                            display: "none",
                          },
                        }}
                        variant="h6"
                        color="primary"
                      >
                        {option}{" "}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </FormControl>
  );
};

export default LanguageSelector;
