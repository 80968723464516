import { useState } from "react";
import { nAllRoutes } from "../../pages/route";
import { Typography, Stack, IconButton, Collapse, Button } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import SmallHeader from "./SmallHeader";
import LanguageSelector from "./LanguageSelector";

const SideNav = ({
  selectedRoute,
  onRouteChange,
  isOpen,
  onToggleHeader,
  selLang,
  allLang,
  onLangChange,
  onSignout
}) => {
  const [openSelected, setOpenSelected] = useState([selectedRoute]);

  if (!isOpen) {
    return;
  }

  return (
    <>
      <SmallHeader
        onToggle={() => {
          onToggleHeader();
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <LanguageSelector
          selLang={selLang}
          allLang={allLang}
          onChange={(d) => {
            onLangChange(d);
          }}
          width={"80px"}
        />
      </div>
      {Object.keys(nAllRoutes[selLang]).map((route) => {
        return (
          <>
            <Stack
              direction={"row"}
              sx={{
                alignItems: "start",
                cursor: "grab",
              }}
              onClick={() => {
                setOpenSelected((s) => {
                  let a = [...s];
                  if (a.includes(route)) {
                    a.splice(a.indexOf(route), 1);
                    return a;
                  } else {
                    return [...s, route];
                  }
                });
              }}
            >
              <Stack
                direction={"row"}
                sx={{
                  display: "flex",
                  flexDirection: "flex-start",
                  padding: "15px 10px 5px 20px",
                }}
              >
                <Typography
                  variant="h6"
                  color={"white"}
                  sx={{ fontSize: "14px" }}
                >
                  {route.slice(0, 2)}
                </Typography>

                <Typography
                  variant="h6"
                  color={"white"}
                  sx={{ fontSize: "14px" }}
                >
                  {`${route.slice(2)}`}
                </Typography>
              </Stack>

              <IconButton color={"#262626"}>
                {openSelected.includes(route) ||
                Boolean(
                  nAllRoutes[selLang][route].find(
                    (it) => it.menuName === selectedRoute
                  )
                ) ? (
                  <KeyboardArrowUp
                    sx={{
                      padding: "10px 10px 0px 0px",
                      color: "#fff",
                    }}
                  />
                ) : (
                  <KeyboardArrowDown
                    sx={{
                      padding: "5px 10px 0px 0px",
                      color: "#fff",
                    }}
                  />
                )}
              </IconButton>
            </Stack>
            <Collapse
              in={
                Boolean(
                  nAllRoutes[selLang][route].find(
                    (it) => it.menuName === selectedRoute
                  )
                ) || openSelected.includes(route)
              }
              timeout="auto"
              unmountOnExit
            >
              {nAllRoutes[selLang][route].map((ro) => {
                return (
                  <Button
                    sx={{
                      width: "100%",
                      justifyContent: "flex-start",
                      padding: 0,
                    }}
                    onClick={() => {
                      onRouteChange(ro);
                      setOpenSelected([route]);
                    }}
                  >
                    <Stack
                      direction={"row"}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        padding: "10px 10px 5px 30px",
                        background:
                          selectedRoute === ro.menuName ? "#111111" : "#282828",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          textAlign: "left",
                          textTransform: "none",
                          color: "white",
                        }}
                      >
                        {ro.menuName.slice(0, 2)}
                      </Typography>

                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          textAlign: "left",
                          textTransform: "none",
                          color: "white",
                        }}
                      >
                        {ro.menuName.slice(2)}
                      </Typography>
                    </Stack>
                  </Button>
                );
              })}
            </Collapse>
          </>
        );
      })}

      <Button
        sx={{
          backgroundColor: "#11111",
          margin: "auto",
          display: "block",
          marginTop: "12px",
        }}
        onClick={() => {
          onSignout()
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            background: "#111111",
            padding: "8px 20px",
            borderRadius: "10px",
            filter: "drop-shadow(1px,2px,2px,rgba(255,255,255,0.25))",
          }}
          color={"#ffff"}
        >
          Signout
        </Typography>
      </Button>

      <Typography
        variant="h6"
        sx={{
          color: "white",
          fontSize: "12px",
          textAlign: "center",
          margin: "10px 10px",
          // width: "100%",
          cursor: "pointer",
        }}
        onClick={() => {
          window.location = "https://www.catalyticcorps.com/";
        }}
      >
        Developed by Catalytic Corps
      </Typography>
    </>
  );
};

export default SideNav;
