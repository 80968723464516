import { TextField } from "@mui/material";

const ValueInput = ({ label, value, onValueChange, required, type }) => {
  return (
    <>
      <TextField
        value={value}
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
        fullWidth
        label={label}
        type={type}
        multiline={false}
        variant="outlined"
        margin="normal"
        maxRows={1}
        sx={{
          margin: "8px 0px",
        }}
        required={Boolean(required)}
      ></TextField>
    </>
  );
};

export default ValueInput;
