import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import DownloadIcon from "@mui/icons-material/Download";
import { Line, Bar } from "react-chartjs-2";
import { Button, Stack } from "@mui/material";
import GraphGroupSwitchContent from "../../common/GraphGroupSwitchContent";
import GraphSwitch from "../../common/GraphSwitch";
import GraphSelector from "../../common/GraphSelector";
import Graph from "../../../utils/graph";
import spansFinder from "../../../utils/spansFinder";
import labelFinder from "../../../utils/labelFinder";
import percentageGraphProcessor from ".././percentageGraphProcessor";
import averageTimeFinder from "./averageTimeFinderGraph";
import downloadExcelData from "../../../utils/excelDownloader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AverageTimeSpentGraph = ({
  analytics,
  isPark,
  isBar,
  isSpan,
  isPercentage,
  onTogglePark,
  onToggleBar,
  onToggleSpan,
  onTogglePercentage,
  title,
  allOptions,
}) => {
  const [sParks, setSParks] = useState([]);
  const parks = Object.keys(analytics);
  let allSpans = useRef(spansFinder(analytics));

  const [data, setData] = useState({});

  useEffect(() => {
    let allSpans = spansFinder(analytics);
    let datasets = averageTimeFinder(
      analytics,
      isPark,
      isSpan,
      isBar,
      allOptions,
      sParks,
      allSpans
    );
    let labels = labelFinder(allSpans, isPark, isSpan, sParks);
    datasets = percentageGraphProcessor(isPercentage, datasets, labels.length);
    setData({ labels, datasets });
  }, [analytics, isSpan, isPark, sParks, isBar, allOptions, isPercentage]);

  useEffect(() => {
    let parks = Object.keys(analytics);
    setSParks(parks);
  }, [analytics, allOptions]);

  return (
    <>
      <div style={{ margin: "20px", paddingTop: "0px" }}>
        <Stack
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
          direction="row"
          spacing={1}
        >
          <GraphSelector
            allOptions={parks}
            selectedOptions={sParks}
            onChange={(s) => {
              setSParks(s);
            }}
            width="120px"
            label={"Facilities"}
          />

          <GraphGroupSwitchContent
            content={
              <>
                <GraphSwitch
                  isChecked={isBar}
                  label={"Bar"}
                  onChange={(v) => {
                    onToggleBar();
                  }}
                />

                {parks.length > 1 ? (
                  <GraphSwitch
                    isChecked={isPark}
                    label={"Park"}
                    onChange={(v) => {
                      onTogglePark();
                    }}
                  />
                ) : (
                  <></>
                )}

                {allSpans.current.length > 1 ? (
                  <GraphSwitch
                    isChecked={isSpan}
                    label={"Span"}
                    onChange={(v) => {
                      onToggleSpan();
                    }}
                  />
                ) : (
                  <></>
                )}

                <GraphSwitch
                  isChecked={isPercentage}
                  label={"Per(%)"}
                  onChange={(v) => {
                    onTogglePercentage();
                  }}
                />
              </>
            }
          />
          <Button
            onClick={() => {
              downloadExcelData(
                isPark,
                isSpan,
                analytics,
                allOptions,
                title,
                sParks
              );
            }}
            sx={{
              border: "1.5px solid #262626",
            }}
          >
            <DownloadIcon />
          </Button>
        </Stack>
        {data &&
          data.datasets &&
          data.datasets.length > 0 &&
          (isBar ? (
            <Bar
              style={{ marginTop: "20px" }}
              options={Graph.BarOptions(
                title,
                true,
                data.datasets.reduce((acc, cur) => {
                  if (!acc[cur.stack]) {
                    let nAcc = { ...acc };
                    nAcc[cur.stack] = [cur];
                    return nAcc;
                  } else {
                    let nAcc = { ...acc };
                    nAcc[cur.stack] = [...nAcc[cur.stack], cur];
                    return nAcc;
                  }
                }, {})
              )}
              data={data}
            />
          ) : (
            <Line
              style={{ marginTop: "20px" }}
              options={Graph.Options(title)}
              data={data}
            />
          ))}
      </div>
    </>
  );
};

export default AverageTimeSpentGraph;
