import { Button, CircularProgress, Typography } from "@mui/material";
import PMCTheme from "../../../theme/theme";

const CustomButton = ({ label, isLoading, onClick }) => {
  return (
    <Button
      variant="outlined"
      size="large"
      sx={{
        backgroundColor: "#262626",
        borderRadius: "30px",
        padding: "10px 15px",
        margin: "20px auto",
        display: "block",
        color: PMCTheme.palette.background.main,
        "&:hover": {
          backgroundColor: PMCTheme.palette.primary.main,
          color: PMCTheme.palette.background.main,
          opacity: 0.6,
        },
      }}
      onClick={() => {
        if (!isLoading) {
          onClick();
        }
      }}
    >
      {isLoading ? (
        <CircularProgress color="background" />
      ) : (
        <Typography variant="h6">{label}</Typography>
      )}
    </Button>
  );
};

export default CustomButton;
