import Graph from "../../utils/graph"


const normalGraphFinder = (
    analytics,
    isPark,
    isSpan,
    isBar,
    allOptions,
    sParks,
    sOptions,
    allSpans,
    isStacked
) => {



    const options = allOptions.filter(ap => sOptions.includes(ap))

    if (!isPark && !isSpan) {

        if (isBar) {
            return options.map((option, oIndex) => {
                return sParks.map((park, pIndex) => {
                    return {
                        label: `${park} - ${option}`,
                        data: allSpans.map((span) => {
                            return analytics[park][span.year][span.part][option]
                        }),
                        borderColor: Graph.Colors[oIndex],
                        backgroundColor: Graph.Colors[oIndex],
                        tension: 0.2,
                        pointBorderWidth: 7,
                        hoverBorderWidth: 10,
                        stack: `${park} ${isStacked ? "" : option}`
                    }
                })
            }).flat()
        }

        return sParks.map((park, pIndex) => {
            return {
                label: park,
                data: allSpans.map((span) => {
                    return options.reduce((acc, cur) => {
                        return acc + analytics[park][span.year][span.part][cur]
                    }, 0)
                }),
                borderColor: Graph.Colors[pIndex],
                backgroundColor: Graph.Colors[pIndex],
                tension: 0.2,
                pointBorderWidth: 7,
                hoverBorderWidth: 10,
            }
        })

    } else if (isPark && !isSpan) {

        if (isBar) {
            return options.map((option, oIndex) => {
                return {
                    label: `${option}`,
                    data: allSpans.map((span) => {
                        return sParks.reduce((acc, cur) => {
                            return acc + analytics[cur][span.year][span.part][option]
                        }, 0)
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    tension: 0.2,
                    pointBorderWidth: 7,
                    hoverBorderWidth: 10,
                    stack: `All Parks ${isStacked ? "" : option}`
                }
            })
        }

        return [
            {
                label: "All",
                data: allSpans.map((span) => {
                    return sParks.reduce((acc, cur) => {
                        return acc + options.reduce((occ, ocur) => {
                            return occ + analytics[cur][span.year][span.part][ocur]
                        }, 0)
                    }, 0)
                }),
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                tension: 0.2,
                pointBorderWidth: 7,
                hoverBorderWidth: 10,
            }
        ]
    } else if (!isPark && isSpan) {


        if (isBar) {
            return options.map((option, oIndex) => {
                return {
                    label: `${option}`,
                    data: sParks.map((park) => {
                        return allSpans.reduce((acc, cur) => {
                            return acc + analytics[park][cur.year][cur.part][option]
                        }, 0)
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    tension: 0.2,
                    pointBorderWidth: 7,
                    hoverBorderWidth: 10,
                    stack: `All Dates ${isStacked ? "" : option}`
                }
            })
        }

        return [{
            label: "All",
            data: sParks.map((park) => {
                return allSpans.reduce((acc, cur) => {
                    return acc + options.reduce((occ, ocur) => {
                        return occ + analytics[park][cur.year][cur.part][ocur]
                    }, 0)
                }, 0)
            }),
            borderColor: Graph.Colors[0],
            backgroundColor: Graph.Colors[0],
            tension: 0.2,
            pointBorderWidth: 7,
            hoverBorderWidth: 10,
        }]
    } else if (isPark && isSpan) {

        if (isBar) {

            return options.map((option, oIndex) => {
                return {
                    label: `${option}`,
                    data: [
                        allSpans.reduce((acc, cur) => {
                            return acc + sParks.reduce((pcc, pcur) => {
                                return pcc + analytics[pcur][cur.year][cur.part][option]
                            }, 0)
                        }, 0)
                    ],
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    tension: 0.2,
                    pointBorderWidth: 7,
                    hoverBorderWidth: 10,
                    stack: `All Dates and Parks ${isStacked ? "" : option}`
                }
            })

        }

        return [
            {
                label: "All",
                data: [
                    allSpans.reduce((acc, cur) => {
                        return acc + sParks.reduce((pcc, pcur) => {
                            return pcc + options.reduce((occ, ocur) => {
                                return occ + analytics[pcur][cur.year][cur.part][ocur]
                            }, 0)
                        }, 0)
                    }, 0)
                ],
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                tension: 0.2,
                pointBorderWidth: 7,
                hoverBorderWidth: 10,
            }
        ]

    }




}

export default normalGraphFinder