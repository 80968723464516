import { createContext, useCallback, useEffect, useState } from "react";
import axios from "axios";
import Constants from "../constant/Constant";

export const AuthContext = createContext({
    isAuthenticated: false,
    isAuthenticating: false,
    department: "",
    authToken: "",
    authenticate: async () => { },
    isError: false,
    errorMessage: "",
    isManagement: false,
    clearMessage: () => { },
    onSignOut: () => { },
})

const AuthContextProvider = ({ children }) => {

    const [authState, setAuthState] = useState({
        isAuthenticated: false,
        isAuthenticating: false,
        department: "",
        authToken: "",
        isError: false,
        socket: undefined,
        isSocketConnected: false,
        isManagement: false,
        errorMessage: ""
    })

    const authenticate = async (username, password) => {

        if (!username || username.length < 6) {
            setAuthState(p => {
                return { ...p, isError: true, errorMessage: "Please enter correct username" }
            })
            return
        }
        if (!password || password.length < 7) {
            setAuthState(p => {
                return { ...p, isError: true, errorMessage: "Please enter correct password" }
            })
            return
        }

        try {
            const response = await axios.post(Constants.authenticationRoute, { username, password })
            if (!response.data.status) {
                setAuthState(prevState => {
                    return {
                        ...prevState,
                        isError: true,
                        errorMessage: response.data?.message ? response.data?.message : "Please try after some time",
                        isAuthenticating: false
                    }
                })
                return
            }
            console.log(response.data)
            setAuthState(prevState => {
                return {
                    ...prevState,
                    isError: true,
                    errorMessage: "Logged In Successfully",
                    isAuthenticated: true,
                    isAuthenticating: false,
                    authToken: response.data.data,
                    department: response.data.department,
                    isManagement: response.data.isManagement
                }
            })
            window.localStorage.setItem("PMCAuthToken", response.data.data)
            window.localStorage.setItem("PMCDepartment", response.data.department)
            window.localStorage.setItem("PMCIsManagement", response.data.isManagement)
        } catch (err) {
            console.log(err)
            setAuthState(prevState => {
                return {
                    ...prevState,
                    isError: true,
                    errorMessage: err.response.data?.message ? err.response.data?.message : "Please try after some time",
                    isAuthenticating: false
                }
            })
        }

    }

    const clearMessage = () => {
        setAuthState(prevState => {
            return {
                ...prevState,
                isError: false,
                errorMessage: "",
            }
        })
    }

    useEffect(() => {
        const token = window.localStorage.getItem("PMCAuthToken")
        const department = window.localStorage.getItem("PMCDepartment")
        const isManagement = window.localStorage.getItem("PMCIsManagement")
        if (token) {
            setAuthState(prevState => {
                return {
                    ...prevState,
                    isError: true,
                    errorMessage: "Logged In Successfully",
                    isAuthenticated: true,
                    isAuthenticating: false,
                    authToken: token,
                    department: department,
                    isManagement: isManagement
                }
            })
        }
    }, [setAuthState])

    const onSignOut = useCallback(() => {

        window.localStorage.clear()

        setAuthState(prevState => {
            return {
                ...prevState,
                isError: true,
                errorMessage: "Logged Out Successfully",
                isAuthenticated: false,
                isAuthenticating: false,
                authToken: undefined,
                department: undefined,
                isManagement: false
            }
        })
    }, [

    ])


    const value = {
        isAuthenticated: authState.isAuthenticated,
        isAuthenticating: authState.isAuthenticating,
        authToken: authState.authToken,
        errorMessage: authState.errorMessage,
        isError: authState.isError,
        authenticate: authenticate,
        clearMessage: clearMessage,
        department: authState.department,
        isManagement: authState.isManagement,
        onSignOut
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )

}

export default AuthContextProvider
