import LoginPage from "./pages/LoginPage";
import AuthContextProvider from "./store/auth";

function App() {
  return (
    <AuthContextProvider >
      <LoginPage />
    </AuthContextProvider>
  );
}

export default App;
