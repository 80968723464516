import { useContext } from "react";
import { AnalyticsContext } from "../../store/analytics";
import Loading from "../common/Loading";
import Constants from "../../constant/Constant";
import NormalGraph from "../graph/NormalGraph";
import attachementFinder from "../../utils/attachementFinder";
import ImagesViewerBox from "../common/ImagesViewerBox";

const ServiceCapacityBuildingScreen = () => {
  const analyticsContext = useContext(AnalyticsContext);

  if (
    analyticsContext.isServiceCapacityBuildingAnalyticsLoading ||
    !analyticsContext.isServiceCapacityBuildingAnalyticsLoaded
  ) {
    return (
      <>
        <Loading />
      </>
    );
  }



  return (
    <div style={{}}>
      <NormalGraph
        analytics={analyticsContext.serviceCapacityBuildingAnalytics}
        isBar={analyticsContext.isBar}
        isPark={analyticsContext.isPark}
        isSpan={analyticsContext.isSpan}
        isPercentage={analyticsContext.isPercentage}
        onToggleBar={() => {
          analyticsContext.onToggleBar();
        }}
        onTogglePark={() => {
          analyticsContext.onTogglePark();
        }}
        onToggleSpan={() => {
          analyticsContext.onToggleSpan();
        }}
        onTogglePercentage={() => {
          analyticsContext.onTogglePercentage();
        }}
        title={"Service Providers Capacity Building Analytics"}
        allOptions={Constants.serviceCapacityBuildingOptions}
        isStacked={analyticsContext.isStacked}
        onToggleStack={() => {
          analyticsContext.onToggleStack();
        }}
        extras={["name","conductedFor","suggestion"]}
      />
      <ImagesViewerBox
        attachements={attachementFinder(analyticsContext.visitorAnalytics)}
      />
    </div>
  );
};

export default ServiceCapacityBuildingScreen;
