import {
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";


const ListWrapper = ({ data, isLoading, onToggleAdder, title, rComponent,width }) => {
  return (
    <Card
      elevation={3}
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: "1",
        width: `${width}%`,
        padding: "15px 10px 10px 15px",
        margin: "40px 15px",
        borderRadius: "10px",
        alignItems: "center",
        background: "#fff",
      }}
    >
      <Stack
        direction="row"
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          fontSize="22px"
          sx={{ color: "#262626", fontWeight: "bold" }}
        >
          {title}
        </Typography>

        <IconButton
          onClick={() => {
            onToggleAdder();
          }}
        >
          <AddIcon
            fontSize="20px"
            // color="primary.main"
            sx={{
              color: "#262626",
            }}
          />
        </IconButton>
      </Stack>

      {isLoading ? (
        <CircularProgress
          sx={{
            minHeight: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
          color="primary"
          size={25}
        />
      ) : (
        <Stack
          style={{
            marginLeft: "10px",
            maxHeight: "400px",
            overflowY: "scroll",
            margin: "12px 0px 15px 15px",
          }}
          spacing={0}
        >
          {data.map((dt, index) => {
            return rComponent(dt, index);
          })}
        </Stack>
      )}
    </Card>
  );
};

export default ListWrapper;
