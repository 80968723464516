import { Stack, Typography } from "@mui/material";

const SmallHeader = ({ onToggle }) => {
  return (
    <Stack
      sx={{
        margin: "10px 0px",
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <img
          style={{
            padding: "10px 10px",
            width: "100px",
            borderRadius: "20px",
          }}
          src="../../assets/PMC.jpeg"
          alt="Urban 95"
        />
      </Stack>

      <Stack>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "Bold",
            color: "#f6f6f6",
            textAlign: "center",
            fontSize: "17px",
            marginTop: "5px",
            marginRight: "5px",
          }}
        >
          Pune Muncipial Corporation
        </Typography>
      </Stack>
      <Stack
        direction={"column"}
        sx={{
          justifyContent: "center",
          padding: "0px 20px",
          alignItems: "center",
        }}
      >
        <img
          style={{
            padding: "10px 10px",
            height: "30px",
          }}
          src="../../assets/user.png"
          alt="Urban 95"
        />
      </Stack>
    </Stack>
  );
};

export default SmallHeader;
