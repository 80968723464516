import { useContext } from "react";
import { AnalyticsContext } from "../../store/analytics";
import Loading from "../common/Loading";
import Constants from "../../constant/Constant";
import NormalGraph from "../graph/NormalGraph";

const PollutionScreen = () => {
  const analyticsContext = useContext(AnalyticsContext);

  if (
    analyticsContext.isPollutionAnalyticsLoading ||
    !analyticsContext.isPollutionAnalyticsLoaded
  ) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <div style={{  }}>
      <NormalGraph
        analytics={analyticsContext.pollutionAnalytics}
        isBar={analyticsContext.isBar}
        isPark={analyticsContext.isPark}
        isSpan={analyticsContext.isSpan}
        isPercentage={analyticsContext.isPercentage}
        onToggleBar={() => {
          analyticsContext.onToggleBar();
        }}
        onTogglePark={() => {
          analyticsContext.onTogglePark();
        }}
        onToggleSpan={() => {
          analyticsContext.onToggleSpan();
        }}
        onTogglePercentage={() => {
          analyticsContext.onTogglePercentage()
        }}
        title={"Pollution Analytics"}
        allOptions={Constants.pollutionAnalyticsOptions}
        isStacked={analyticsContext.isStacked}
        onToggleStack={() => {
          analyticsContext.onToggleStack();
        }}
      />
    </div>
  );
};

export default PollutionScreen
