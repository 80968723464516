import * as FileSaver from "file-saver"
import XLSX from "sheetjs-style"
import spansFinder from "./spansFinder"

const downloadExcelData = (
    isParkSummed,
    isSpanSummed,
    data,
    aOptions,
    title,
    sParks,
    extras
) => {

    let options = [...aOptions,...extras]

    if (!isParkSummed && !isSpanSummed) {

        const wb = XLSX.utils.book_new()

        Object.keys(data).filter(p => sParks.includes(p)).forEach(parkName => {
            const parkData = data[parkName]
            const allSpans = spansFinder(data)

            const parkDataArrays = allSpans.map(span => {
                return Object.fromEntries(
                    [
                        ["Span", `${span.year}-${span.part}`],
                        ...(
                            options.map(option => {
                                return [`${option}`, parkData[span.year][span.part][option]]
                            })
                        )
                    ]
                )
            })
            const ws = XLSX.utils.json_to_sheet(parkDataArrays)
            XLSX.utils.book_append_sheet(wb, ws, parkName.slice(0,30))
        })
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const bData = new Blob([excelBuffer], { type: "application/octet-stream" });
        FileSaver.saveAs(bData, `${title.slice(0,20)}.xlsx`);


    } else if (isParkSummed && !isSpanSummed) {



        const wb = XLSX.utils.book_new()

        const allSpans = spansFinder(data)

        const parkDataArrays = allSpans.map(span => {
            return Object.fromEntries(
                [
                    ["Span", `${span.year}-${span.part}`],
                    ...(
                        options.map(option => {
                            return [`${option}`, Object.keys(data).reduce((acc, cur) => {
                                return acc + data[cur][span.year][span.part][option]
                            }, 0)]
                        })
                    )
                ]
            )
        })

        const ws = XLSX.utils.json_to_sheet(parkDataArrays)
        XLSX.utils.book_append_sheet(wb, ws, "All Parks")

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const bData = new Blob([excelBuffer], { type: "application/octet-stream" });
        FileSaver.saveAs(bData, "data.xlsx");


    } else if (!isParkSummed && isSpanSummed) {



        const wb = XLSX.utils.book_new()

        const allSpans = spansFinder(data)

        const parkDataArrays = Object.keys(data).map(parkName => {
            return Object.fromEntries(
                [
                    ["Park", `${parkName}`],
                    ...(
                        options.map(option => {
                            return [`${option}`, allSpans.reduce((acc, span) => {
                                return acc + data[parkName][span.year][span.part][option]
                            }, 0)]
                        })
                    )
                ]
            )
        })

        const ws = XLSX.utils.json_to_sheet(parkDataArrays)
        XLSX.utils.book_append_sheet(wb, ws, "All Dates")

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const bData = new Blob([excelBuffer], { type: "application/octet-stream" });
        FileSaver.saveAs(bData, "data.xlsx");




    } else if (isParkSummed && isSpanSummed) {



        const wb = XLSX.utils.book_new()

        const allSpans = spansFinder(data)

        const parkDataArrays = [
            Object.fromEntries([
                ["Span", "All Dates And Parks"],
                ...(
                    options.map((option) => {
                        return [option, allSpans.reduce((acc, cur) => {
                            return acc + Object.keys(data).reduce((pcc, pcur) => {
                                return pcc + data[pcur][cur.year][cur.part][option]
                            }, 0)
                        }, 0)]
                    })
                )
            ])
        ]

        const ws = XLSX.utils.json_to_sheet(parkDataArrays)
        XLSX.utils.book_append_sheet(wb, ws, "All Dates And Parks")

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const bData = new Blob([excelBuffer], { type: "application/octet-stream" });
        FileSaver.saveAs(bData, "data.xlsx");



    }

}

export default downloadExcelData