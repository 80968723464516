import moment from "moment-timezone"


const findBodyData = (
    startDay,
    days,
    type
) => {


    const date = moment.tz(startDay, "DD-MM-YYYY", "Asia/Kolkata")
    const eDate = date.clone().add(days, "day")

    const startYear = date.year()

    if (type === "mon") {

        const startMonth = date.month() + 1
        const noOfMonths = eDate.diff(date, "month")

        return {
            startMonth: startMonth,
            startYear: startYear,
            months: noOfMonths,
        }

    } else if (type === "quart") {

        const startQuarter = date.quarter()
        const noOfQuarters = eDate.diff(date, "quarter")

        return {
            startQuarter: startQuarter,
            startYear: startYear,
            quarters: noOfQuarters,
        }

    } else if (type === "term") {

        const startTerm = (date.quarter() <= 2) ? 1 : 2
        const noOfTerms = eDate.diff(date, "months") / 6

        return {
            startTerm: startTerm,
            startYear: startYear,
            terms: noOfTerms
        }
    }

}


export default findBodyData