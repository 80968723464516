import { FormControlLabel, Switch } from "@mui/material";


const GraphSwitch = ({ isChecked, label, onChange }) => {
  return (
    <FormControlLabel
      color="primary"
      control={
        <Switch
          checked={isChecked}
        
          onChange={(event) => {
            onChange(event.target.checked);
          }}
        />
      }
      label={label}
    />
  );
};

export default GraphSwitch;
