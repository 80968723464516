import { useEffect, useState } from "react";
import allRoutes, { nAllRoutes } from "./route";
import { Grid } from "@mui/material";
import SideNav from "../components/common/SideNav";
import AnalyticsContextProvider from "../store/analytics";
import PMCStack from "../components/stacks/PMCStack";

const HomePage = ({onSignout}) => {
  const [selLang, setSelLang] = useState(Object.keys(nAllRoutes)[0]);

  const [selRoute, setSelRoute] = useState(Object.values(nAllRoutes[selLang])[0][0]);
  const [isHeaderOpen, setIsHeaderOpen] = useState(true);

  useEffect(() => {
    const url = new URL(window.location.href);
    let sideNaveName = url.searchParams.get("sideNavName");
    let route = Object.values(allRoutes)
      .flat()
      .find((ro) => ro.menuName === sideNaveName);
    if (sideNaveName && Boolean(route)) {
      setSelRoute(route);
    } else {
      const url = new URL(window.location.href);
      url.searchParams.set(
        "sideNavName",
        Object.values(allRoutes)[0][0].menuName
      );
      window.history.pushState({}, "", url);
    }
  }, []);

  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Grid
        container
        sx={{
          flexGrow: 1,
          flexDirection: "row",
        }}
        style={{ height: "calc(100% - 100px)", width: "100%" }}
      >
        <Grid
          item
          sx={{
            boxShadow: "2px 0px 0px 0px rgba(0, 0, 0, 0.20)",
            height: "100%",
            width: isHeaderOpen ? "240px" : "0px",
            backgroundColor: "#262626",
            overflow: "auto",
            "::-webkit-scrollbar": {
              display: "none",
            },
            transition: "width 400ms",
          }}
        >
          <SideNav
            selLang={selLang}
            allLang={Object.keys(nAllRoutes)}
            selectedRoute={selRoute.menuName}
            onLangChange={(d) => {
              setSelLang(d);
            }}
            onRouteChange={(r) => {
              const url = new URL(window.location.href);
              url.searchParams.set("sideNavName", r.menuName);
              window.history.pushState({}, "", url);
              setSelRoute(r);
            }}
            isOpen={isHeaderOpen}
            onToggleHeader={() => {
              setIsHeaderOpen((p) => {
                return !p;
              });
            }}
            onSignout={() => {
              onSignout()
            }}
          />
        </Grid>

        <Grid
          item
          sx={{
            overflow: "auto",
            height: "100%",
            width: "100%",
            position: "relative",
            paddingLeft: "10px",
            "::-webkit-scrollbar": {
              display: "none",
            },
            transition: "width 400ms",
          }}
          style={{
            width: isHeaderOpen ? `calc(100% - 240px)` : "100%",
          }}
        >
          <AnalyticsContextProvider menuIndex={selRoute.menuRouteIndex}>
            <PMCStack
              selRoute={selRoute.menuName}
              dataFrequency={selRoute.dataFrequency}
              menuIndex={selRoute.menuRouteIndex}
            />
          </AnalyticsContextProvider>
        </Grid>
      </Grid>
    </div>
  );
};

export default HomePage;
