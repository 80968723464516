import { InputLabel, MenuItem, Select, Typography } from "@mui/material";


const SelectInput = ({ label, options, value, onValueChange,required }) => {
  return (
    <>
      <InputLabel
        variant="standard"
        sx={{
          width: "100%",
          margin: "3px 0px",
        }}
      >
        <Typography variant="h6" color="primary" fontSize="15px">
          {label}
        </Typography>
      </InputLabel>
      <Select
        fullWidth
        value={value}
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
        variant="outlined"
        margin="none"
        label="Hotel"
        sx={{
          margin: "8px 0px",
        }}
        required={Boolean(required)}
        style={{
          marginLeft: "1px",
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem sx={{
                color:"#262626"
            }} key={option + Math.random()} value={option}>
              {option.split("_").join(" ")}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default SelectInput;
