const labelFinder = (allSpans, isPark, isSpan,sParks) => {
    if (!isPark && !isSpan) {
        return allSpans.map(a => `${a.year}-${a.part}`)
    } else if(isPark && !isSpan){
        return allSpans.map(a => `${a.year}-${a.part}`)
    }else if(!isPark && isSpan){
        return sParks.map(a => a.slice(0,20))
    }else {
        return "All Parks"
    }
}

export default labelFinder