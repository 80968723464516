import { useContext } from "react";
import { AnalyticsContext } from "../../store/analytics";
import Loading from "../common/Loading";
import Constants from "../../constant/Constant";
import AverageTimeSpentGraph from "../graph/visitors/AverageTimeGraph";

const AverageTimeSpentGraphScreen = () => {
  const analyticsContext = useContext(AnalyticsContext);

  if (
    analyticsContext.isVisitorAnalyticsLoading ||
    !analyticsContext.isVisitorAnalyticsLoaded
  ) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <div style={{}}>
      <AverageTimeSpentGraph
        analytics={analyticsContext.visitorAnalytics}
        isBar={analyticsContext.isBar}
        isPark={analyticsContext.isPark}
        isSpan={analyticsContext.isSpan}
        isPercentage={analyticsContext.isPercentage}
        onToggleBar={() => {
          analyticsContext.onToggleBar();
        }}
        onTogglePark={() => {
          analyticsContext.onTogglePark();
        }}
        onToggleSpan={() => {
          analyticsContext.onToggleSpan();
        }}
        onTogglePercentage={() => {
          analyticsContext.onTogglePercentage();
        }}
        title={"Average Time Spent Analytics"}
        allOptions={Constants.visitorAnalyticsOptions}
        isStacked={analyticsContext.isStacked}
        onToggleStack={() => {
          analyticsContext.onToggleStack();
        }}
      />
    </div>
  );
};

export default AverageTimeSpentGraphScreen;
