

const percentageConverter = (
    datasets,
    dataSize
) => {

    let commonData = []
    let formattedData = datasets.reduce((acc, cur) => {
        const stack = cur.stack
        if (stack) {
            if (!acc[stack]) {
                let nAcc = { ...acc };
                nAcc[stack] = [cur];
                return nAcc;
            } else {
                let nAcc = { ...acc };
                nAcc[stack] = [...nAcc[stack], cur];
                return nAcc;
            }
        } else {
            commonData.push(cur)
            return acc
        }
    }, {})

    let allStacks = Object.keys(formattedData)
    let nFData = {}

    for (let i = 0; i < allStacks.length; i++) {
        const stackName = allStacks[i]
        const stackData = formattedData[stackName]
        const calData = percentageDataConverterHelper(
            stackData.map(it => it.data),
            dataSize
        )
        nFData[stackName] = stackData.map((it, ind) => {
            return {
                ...it,
                data: calData[ind]
            }
        })
    }

    return [
        ...Object.values(nFData).flat(),
        ...(commonData.map(cm => {
            return {
                ...cm,
                data: percentageCommonDataConverterHelper(dataSize)
            }
        }))
    ]

}

const percentageDataConverterHelper = (data, dataSize) => {

    if (data.length <= 0) {
        return []
    }
    let total = []
    for (let i = 0; i < dataSize; i++) {
        let sum = 0
        for (let j = 0; j < data.length; j++) {
            sum = sum + data[j][i]
        }
        total.push(sum)
    }
    return data.map(aData => {
        const result = aData.map((dt, index) => Math.round(((dt / total[index]) * 100) * 100) / 100)
        return result
    })
}

const percentageCommonDataConverterHelper = (dataSize) => {
    return Array.from({ length: dataSize }, () => 100);
}

const percentageGraphProcessor = (
    isPercentage,
    dataset,
    datasize
) => {

    if (isPercentage) {
        return percentageConverter(
            dataset,
            datasize
        )
    } else {
        return dataset
    }
}

export default percentageGraphProcessor