import axios from "axios"

const AddRequest = async (route, token, data) => {
    try {
        const response = await axios({
            method: "POST",
            url: route,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: data
        })
        return response.data
    } catch (err) {
        console.log(err)
        return err.response.data
    }
}

export default AddRequest