import { InputLabel, MenuItem, Select, Typography } from "@mui/material";


const ComponentSelectInput = ({
  label,
  value,
  onValueChange,
  datas,
  component,
  required
}) => {
  return (
    <>
      <InputLabel
        variant="standard"
        sx={{
          width: "100%",
          margin: "3px 0px",
        }}
      >
        <Typography variant="h6" color="primary" fontSize="15px">
          {label}
        </Typography>
      </InputLabel>
      <Select
        fullWidth
        value={value}
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
        variant="outlined"
        margin="none"
        label="Hotel"
        sx={{
          margin: "8px 0px",
        }}
        required={Boolean(required)}
        style={{
          marginLeft: "1px",
        }}
      >
        {datas.map((dt) => {
          return <MenuItem key={dt._id} value={dt._id} sx={{
            color:"#262626"
          }}>
            {component(dt)}
          </MenuItem>;
        })}
      </Select>
    </>
  );
};

export default ComponentSelectInput;
